
import ProductItemPack from './ProductItemPack'
import defaultProductImg from "../images/default-product.png";
import { digitGroup } from '../Utils';

const proxyUrl = require("../../../package.json").proxy;

const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export default function MenuProductItem({ search, idData, produit, setShowFullPic, setFullImgLabel, setFullImgSrc }) {

    // on va utiliser autoselect pour l'after maj du SelectProduct

    const pdt = produit;

    const idModal = "pack_group" + pdt.IdProduit;

    const actualImgSrc = proxyUrl + "/uploads/" + idData + "/" + pdt.IdProduit + "-3.png";

    const pack_selected_items = {};

    const pack_level_elements = {};

    // si le pdt est un pack alors on prépare sa composition

    if (pdt.EstPack) {

        levels.forEach(level => {
            pack_level_elements[level] = [];
            pack_selected_items[level] = {};
        })

        pdt.compoUnique = true;

        pdt.compositionArray.forEach(packElt => {

            pack_level_elements[packElt.Rang].push(packElt);

            if (pack_level_elements[packElt.Rang].length > 1) {

                pdt.compoUnique = false;
            }
        });
    }

    function get_pack_selected_items([level, selected_item]) {

        pack_selected_items[level] = pack_level_elements[level][selected_item];
    }

    const handleClick = function () {

        setFullImgLabel(pdt.NomProduit)
        setFullImgSrc(actualImgSrc)
        setShowFullPic(true)
    }

    if ((search) && (pdt.IdProduit === -1)) {
        return <span></span>
    }

    return (
        <div id={"pdt" + pdt.IdProduit}>

            <div onClick={handleClick} className="row border-tertiary border-top" style={{ marginBottom: "20px", background: search ? "seashell" : "" }}>

                <div className="col-3 text-center">

                    <img
                        className="h-100 rounded"
                        style={{ maxHeight: "150px" }}
                        src={actualImgSrc} onError={(e) => { e.target.onerror = null; e.target.src = defaultProductImg }}
                        alt="Product"
                    />

                </div>

                <div className="col-9 my-auto">
                    <p
                        className="fs-3 p-0 m-1"
                        style={{ textAlign: "start", textTransform: "uppercase", color: "brown" }}
                    >
                        {pdt.NomProduit + (pdt.IdFraction>0 ? " (1/" + pdt.IdFraction + ")" : "")}
                    </p>

                    <p
                        className="fs-3 p-0 m-1"
                    >
                        {digitGroup(pdt.PU)}</p>

                    {pdt.Details &&
                        <p
                            className="fs-3 p-0 m-1 fw-light fst-italic"
                        >
                            {pdt.Details}</p>
                    }
                </div>
            </div>

            {
                (pdt.EstPack === true) &&
                <>
                    <ProductItemPack
                        idModal={idModal}
                        packOpen={false}
                        setPackOpen={() => { }}
                        productName={pdt.NomProduit}
                        packQty={1}
                        sendCommandToNewRound={() => { }}
                        pack_level_elements={pack_level_elements}
                        get_pack_selected_items={get_pack_selected_items} />
                </>
            }
        </div >
    )
};