import { useState } from "react";
import { EmptyComponent } from "./Utils";

const { default: PaginatedItems } = require("./Paginator");

export const ResponsiveTable = function ({ itemsPerPage, listOfItems, table_titles, Row, LastRow = EmptyComponent }) {

    const [currentItems, setCurrentItems] = useState([]);

    return <>

        <PaginatedItems itemsPerPage={itemsPerPage}
            items={listOfItems}
            setCurrentItems={setCurrentItems}
        />

        <p className="fs-3">nb: {currentItems.length + " / " + listOfItems.length}</p>

        <div className="table-responsive">
            <table className="table table-hover">
                <thead >
                    <tr className="fs-3">
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col" className="text-center">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="fs-2 text-end">
                    {currentItems.map((comp_elt, i) => (
                        <Row
                            key={i}
                            elt={comp_elt}
                        />
                    ))}
                    <LastRow data={listOfItems} />
                </tbody>
            </table>
        </div>
    </>
}