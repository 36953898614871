import defaultProductImg from "../images/default-product.png";

// Mon composant pour afficher une image en plein écran

export default function MyFullScreenDiv({ setShowFullPic, fullImgLabel, fullImgSrc }) {

    return (
        <div
            className="modalDiv col d-flex align-items-center justify-content-center"
            onClick={() => {
                setShowFullPic(false)
            }}
        >
            <button className="btn btn-lg row text-center">

                <div className="row d-flex align-items-center justify-content-center bg-light m-3 p-3" style={{ height: "75%" }}>

                    <div className="m-3 p-3">
                        <img
                            style={{ borderRadius: "20px", width: "auto", maxHeight: "800px", maxWidth: "800px" }}
                            src={fullImgSrc} onError={(e) => { e.target.onerror = null; e.target.src = defaultProductImg }}
                            alt="my Product"
                        />
                    </div>

                    <div className="py-3 mt-3 display-4 bg-light">
                        {fullImgLabel}
                    </div>
                </div>

            </button>
        </div >
    );
}
