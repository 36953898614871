import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ShopForm from "./ShopForm";
import { digitGroup, NbItems } from "../Utils";
import Header from "../Header";
import PaginatedItems from "../Paginator";
import { MyInputSearch } from "../FormElements";
const proxyUrl = require("../../../package.json").proxy;

const ShopAdmin = function (props) {

    // sp = shop_product

    const { t } = useTranslation();

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [shopProducts, setShopProducts] = useState([]);
    const [shopProductsFilt, setShopProductsFilt] = useState([]);
    const [sp_selected, setSP_selected] = useState(null)

    const [sp_id, setSP_id] = useState(0); // pour savoir quel élément supprimer

    const [formOpen, setFormOpen] = useState(false);

    const [refreshList, setRefreshList] = useState(false);

    const toggleForm = () => {
        if (formOpen) setSP_selected(null);
        setFormOpen(!formOpen);
    }

    const refresh_list = () => {

        setRefreshList(!refreshList);
        setFormOpen(false)
        setSP_selected(null)
    }

    const selectSP = (comp) => {

        setConfirmDelete(false)

        setSP_selected(comp);

        toggleForm();
    }

    // fetch SP
    useEffect(() => {

        const fetchSP = async () => {
            try {
                const response = await fetch(proxyUrl + '/shop_products');
                const data = await response.json();
                setShopProducts(data);
                setShopProductsFilt(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchSP();

    }, [refreshList])


    const deleteSP = () => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/shop_product', {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({ id: sp_id })
                });

                if (response.status === 201) window.location.reload();

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmDelete(false)
    }

    const deleteSP_onclick = (id) => {
        setSP_id(id)
        setConfirmDelete(!confirmDelete)
    }

    const RenderSP = () => {

        const [currentItems, setCurrentItems] = useState([]);

        const table_titles = [
            "id",
            "A",
            t("composante.denomination") + " FR",
            t("composante.denomination") + " EN",
            t("composante.price"),
            t("shop.form3"),
            t("shop.form4"),
            "Options"
        ]

        return <>
            <PaginatedItems itemsPerPage={15} items={shopProductsFilt} setCurrentItems={setCurrentItems} />

            <table className="table table-hover">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={table_titles.length} >

                            <NbItems count={currentItems.length} total={shopProductsFilt.length} />
                        </td>
                    </tr>
                    {shopProductsFilt.map((sp_elt) => (
                        <tr key={sp_elt.id}>
                            <th scope="row">{sp_elt.id}</th>
                            <td>{sp_elt.active ? <span style={{ color: "green" }}>ON</span> : <span style={{ color: "brown" }}>Off</span>}</td>
                            <td>{sp_elt.denomination}</td>
                            <td>{sp_elt.denominationEN}</td>
                            <td>{digitGroup(sp_elt.price)}</td>
                            <td>{sp_elt.description}</td>
                            <td>{sp_elt.descriptionEN}</td>
                            <td>
                                <button className="btn" onClick={() => selectSP(sp_elt)}>
                                    <img style={{ height: "35px", width: "auto" }} src="/images/pencil.svg" alt="Crayon" />
                                </button>
                                <button className="btn" onClick={() => deleteSP_onclick(sp_elt.id)}>
                                    <img style={{ height: "35px", width: "auto" }} src="/images/garbage-trash.svg" alt="Corbeille" />
                                </button>
                                {(confirmDelete && (sp_elt.id === sp_id)) && <button className="btn btn-danger fs-5" type="button" onClick={deleteSP}>Confirm Delete</button>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    return <div>

        <Header user={props.user} />

        <div className="body fs-5">

            <a className="my-3 fs-4 btn btn-lg btn-outline-secondary align-middle" href="/settings">
                {t("settings.back")}
            </a>

            <h1>{t("shop.title")}</h1>

            <button className={"btn btn-lg fs-4 mb-3 btn-" + (sp_selected === null ? (formOpen ? "outline-info" : "info") : "danger")} onClick={() => toggleForm()}>
                {sp_selected === null ? t("shop.btn_add") : t("ordi.btn_cancel_modify")}
            </button>

            {formOpen && <ShopForm sp={sp_selected} refresh_list={refresh_list} />}

            <MyInputSearch
                myLabel="Rechercher 1 Article"
                idGroupe={100}
                items={shopProducts}
                setItemsFiltered={setShopProductsFilt}
                filt1="id"
                filt2="denomination"
            />

            <RenderSP />
        </div>

    </div>
}

export default ShopAdmin;