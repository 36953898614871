import { useTranslation } from "react-i18next";
import { format_date } from "./Utils";
import Footer from "./Footer";

export const UserAccessExpired = function ({user}) {
    
    const { t } = useTranslation();

    return <div className="display-5">
        <p style={{ margin: "50px" }}>
            {t("general.user_access_expired")}
        </p>
        <p style={{ margin: "50px" }} className="text-danger">{format_date(user.dateFin)}</p>
        <Footer />
    </div>
}