import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MySelectSearch, MyInput } from "../FormElements";
import { MyScrollButton, NbItems, displaySnackbar, format_date, liste_priv, slang, userHasPriv } from "../Utils";
import SettingsLicences from "./SettingsLicences";
import ListeUsersAccesCloud from "./ListeUsersAccesCloud";
import { ResponsiveTable } from "../ResponsiveTable";
const proxyUrl = require("../../../package.json").proxy;

const SettingsDossier = function (props) {

    const [onglet, setOnglet] = useState(1);

    const { t } = useTranslation();

    const handleChoice = function (choice) {
        setOnglet(choice);
    };

    const [users, setUsers] = useState([])
    const [refreshUser, setRefreshUser] = useState(false)

    const [doss_clts, setDoss_clts] = useState([])
    const [datas, setDatas] = useState([])
    const [activites, setActivites] = useState([])
    const [machines, setMachines] = useState([])
    const [logActions, setLogActions] = useState([])
    const [famille_groupes, setFamilleGroupes] = useState([])
    const [activiteFamilles, setActiviteFamilles] = useState([])
    const [refreshPage, setRefreshPage] = useState({
        r_doss_clts: false,
        r_data: false,
        r_machines: false,
        r_activites: false,
        r_familles: false,
        r_activites_familles: false,
    })

    const [current_doss_clt, setCurrent_Doss_Clt] = useState({
        nomClient: "",
        details: "",
        dateDebut: Date.now()
    })

    const basicDataSelection = {
        idData: 0,
        commentData: "",
        menuNomEntreprise: "",
        menuDescription: "",
        menuWhatsapp: "",
        menuTarification: 1,
    }

    const [current_selection, setCurrent_Selection] = useState({
        idDossierClient: 0,
        idMachine: 0,
        idActivite: 0,
        idFamilleGroupe: 0,
        commentMachine: "",
        nomActivite: "",
        ...basicDataSelection
    })

    const [newDossClt, setNewDossClt] = useState({
        formOpen: false,
        nomClient: "",
        details: "",
    })

    const [delete_id, setDelete_id] = useState(0); // pour savoir quel élément supprimer

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [section, setSection] = useState(0);

    const [createOrModify, setCreateOrModify] = useState(0);

    // Fetch Users
    useEffect(() => {

        if (current_selection.idDossierClient === 0) return

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/users/' + current_selection.idDossierClient);
                const data = await response.json();

                setUsers(data);

            } catch (error) {
                console.error(error);
            }
        })();
    }, [refreshUser, current_selection.idDossierClient])

    // Fetch DossierClient
    useEffect(() => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/doss_clts');
                const data = await response.json();

                // S'il n'y a qu'un seul dossier client alors on ne doit pas se fatiguer à le sélectionner

                if (data.length === 1) {
                    setCurrent_Selection(function (c) { return { ...c, idDossierClient: data[0].id } })
                }

                setDoss_clts(data);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [refreshPage.r_doss_clts, setCurrent_Selection])


    // Fetch Data
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(proxyUrl + '/datas/-1');
                const data = await response.json();
                setDatas(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [refreshPage.r_data])

    // Fetch Machines
    useEffect(() => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/machines/-1');
                const data = await response.json();
                setMachines(data);

            } catch (error) {
                console.error(error);
            }
        })();
    }, [refreshPage.r_machines])

    // Fetch LogActions
    useEffect(() => {

        if (current_selection.idMachine === 0) return

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/log_info/' + current_selection.idMachine);
                const data = await response.json();

                setLogActions(data);

            } catch (error) {
                console.error(error);
            }
        })();
    }, [refreshPage.r_machines, refreshPage.r_data, current_selection.idMachine])

    // Fetch Activite
    useEffect(() => {

        const fetchActivite = async () => {
            try {
                const response = await fetch(proxyUrl + '/activites/' + current_selection.idDossierClient);
                const data = await response.json();
                setActivites(data);

            } catch (error) {
                console.error(error);
            }
        };

        fetchActivite();

    }, [current_selection.idDossierClient, refreshPage.r_activites])

    // Fetch Famille_Groupe
    useEffect(() => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/famille_groupe/' + current_selection.idDossierClient);
                const data = await response.json();

                setFamilleGroupes(data);

            } catch (error) {
                console.error(error);
            }
        })();

    }, [current_selection.idDossierClient, refreshPage.r_familles])

    // Fetch Activite_Familles
    useEffect(() => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/activite_familles/' + current_selection.idActivite);
                const data = await response.json();
                setActiviteFamilles(data);

            } catch (error) {
                console.error(error);
            }
        })();

    }, [current_selection.idActivite, refreshPage.r_activites_familles])

    // Modify Data
    const handleModifyData = async (e) => {

        e.preventDefault()

        const url = proxyUrl + "/data_modify/" + current_selection.idData;

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",
            body: JSON.stringify({
                comment: current_selection.commentData,
                idDossierClient: current_selection.idDossierClient,
                menuNomEntreprise: current_selection.menuNomEntreprise,
                menuDescription: current_selection.menuDescription,
                menuWhatsapp: current_selection.menuWhatsapp,
                menuTarification: current_selection.menuTarification,
            }),
        })

        setCurrent_Selection({ ...current_selection, ...basicDataSelection })

        if (response.status === 201) {

            displaySnackbar("Data " + current_selection.idData + slang(" : modifié", " : changed successfully"), slang("Succès", "Success"), "success")
            setCurrent_Selection({ ...current_selection, idData: 0 })
            setRefreshPage({ ...refreshPage, r_data: !refreshPage.r_data })
        }
    }

    // Modify Machine
    const handleModifyMachine = async (e) => {

        e.preventDefault()

        const url = proxyUrl + "/machine_modify/" + current_selection.idMachine;

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",
            body: JSON.stringify({
                comment: current_selection.commentMachine,
                idDossierClient: current_selection.idDossierClient,
            }),
        })

        setCurrent_Selection({ ...current_selection, commentMachine: "" })

        if (response.status === 201) {

            displaySnackbar("Machine " + current_selection.idMachine + slang(": modifié", ": changed successfully"), slang("Succès", "Success"), "success")
            setCurrent_Selection({ ...current_selection, idMachine: 0 })
            setRefreshPage({ ...refreshPage, r_machines: !refreshPage.r_machines })
        }
    }

    // Create Activite
    const handleCreateActivite = async (e) => {

        e.preventDefault()

        if (current_selection.nomActivite.length === 0) {

            displaySnackbar(slang("Le nom de l'activité est requis", "The activity's name is required"), slang("Attention", "Warning"), "warning")
            return
        }

        const url = proxyUrl + "/activite";

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",
            body: JSON.stringify({
                nomActivite: current_selection.nomActivite,
                idDossierClient: current_selection.idDossierClient,
            }),
        })

        setCurrent_Selection({ ...current_selection, nomActivite: "" })

        if (response.status === 201) {

            displaySnackbar(slang("Nouvelle activité ajoutée", "New Activity created and added"), slang("Succès", "Success"), "light")
            setRefreshPage({ ...refreshPage, r_activites: !refreshPage.r_activites })
        }
    }

    // Create Activite Famille
    const addActiviteFamille = async () => {

        const decomposeIdFamille = function () {
            const id_fam_complex = current_selection.idFamilleGroupe;

            if (!id_fam_complex) return [0, 0]

            if (id_fam_complex === 0) return [0, 0]

            return [Math.floor(id_fam_complex / 1000), id_fam_complex % 1000]
        }

        const [idData_to_save, idFamille_to_save] = decomposeIdFamille()

        if ((idData_to_save === 0) || (idFamille_to_save === 0)) {
            displaySnackbar(slang("Sélectionnez une famille svp", "please select a family"), slang("Sélection incomplète", "incomplete selection"), "warning")
            return
        }

        if (current_selection.idActivite === 0) {

            displaySnackbar(slang("Sélectionnez une activité svp", "please select an activity"), slang("Sélection incomplète", "incomplete selection"), "warning")
            return
        }

        // on vérifie que la famille n'appartient pas déjà à cette Activité
        let already_present = false;

        activiteFamilles.forEach((f) => {

            if (already_present) return

            if ((f.idFamilleGroupe === idFamille_to_save) && (f.idData === idData_to_save)) {

                already_present = true
                return
            }
        })

        if (already_present) {

            displaySnackbar(slang("Cette famille est déjà dans la liste", "This family is already in the list"), slang("doublon interdit", "no duplicate allowed"), "warning")
            return
        }

        const response = await fetch(proxyUrl + "/activite_famille", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",
            body: JSON.stringify({
                idData: idData_to_save,
                idFamilleGroupe: idFamille_to_save,
                idActivite: current_selection.idActivite,
            }),
        })

        if (response.status === 201) {

            displaySnackbar("Famille ajoutée à l'activité " + current_selection.idActivite, "Success", "light")
            setRefreshPage({ ...refreshPage, r_activites_familles: !refreshPage.r_activites_familles })
        }
    }

    // Create Dossier_Client
    const handleCreateDossierClient = async (e) => {

        e.preventDefault()

        const url = (createOrModify ? proxyUrl + "/dossier_client" : proxyUrl + "/dossier_client/" + current_selection.idDossierClient);

        const compose_body = function () {

            let result = {}

            if (createOrModify) {

                if (newDossClt.nomClient === "") {
                    result.nomClient = "NoName_" + format_date(current_doss_clt.dateDebut)
                } else {
                    result.nomClient = newDossClt.nomClient
                }

                result.details = newDossClt.details

            } else {

                if (newDossClt.nomClient !== "") {
                    result.nomClient = newDossClt.nomClient
                }

                if (newDossClt.details !== "") {
                    result.details = newDossClt.details
                }
            }

            return result
        }

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",
            body: JSON.stringify(compose_body()),
        })

        if (response.status === 201) {

            displaySnackbar(createOrModify
                ? slang("Nouveau dossier client ajouté", "New customer added")
                : slang("Dossier client modifié", "Customer file modified")
                , slang("Succès", "Success")
                , "light")
            setNewDossClt({ ...newDossClt, formOpen: false })
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }

    // Compose new Dossier Client
    const compose_doss_clt = function (myName, myValue) {

        const newSelection = { ...newDossClt, [myName]: myValue };

        setNewDossClt(newSelection);
    }

    // Refresh Selection
    const refresh_selection = function (myName, myValue) {

        const newSelection = { ...current_selection, [myName]: myValue };

        if (myName === "idDossierClient") {

            const idx = doss_clts.findIndex((d) => { return d.id === newSelection.idDossierClient })

            if (idx !== -1) setCurrent_Doss_Clt(doss_clts[idx])
        }

        if (myName === "idData") { // ça veut dire qu'on vient de sélectionner un data
            // alors on charge ses précédentes informations

            const idx = datas.findIndex((d) => { return d.id === newSelection.idData })

            if (idx !== -1) {
                const current_data = datas[idx]

                newSelection.commentData = current_data.commentData
                newSelection.menuNomEntreprise = current_data.menuNomEntreprise
                newSelection.menuDescription = current_data.menuDescription
                newSelection.menuWhatsapp = current_data.menuWhatsapp
                newSelection.menuTarification = current_data.menuTarification
            }
        }

        setCurrent_Selection(newSelection);
    }

    // click on delete button : Data, Machine, Activite
    const deleteElement = () => {

        (async () => {
            try {

                let url = "";
                let body = {};
                let method = "";
                let message = "";
                let refreshName = "";

                switch (section) {
                    case 1:
                        url = proxyUrl + '/data_reset_doss_clt';
                        body = { idData: delete_id };
                        method = "POST";
                        message = slang("Data enlevé", "Data removed")
                        refreshName = "r_datas"
                        break;

                    case 2:
                        url = proxyUrl + '/machine_reset_doss_clt';
                        body = { idMachine: delete_id };
                        method = "POST"
                        message = slang("Machine enlevée", "Computer removed")
                        refreshName = "r_machines"
                        break;

                    case 3:
                        url = proxyUrl + '/activite';
                        body = { idActivite: delete_id };
                        method = "DELETE"
                        message = slang("Activité supprimée", "Activity deleted")
                        refreshName = "r_activites"
                        break;

                    case 4:
                        url = proxyUrl + '/activite_famille';
                        body = {
                            idActivite: current_selection.idActivite,
                            idFamilleGroupe: delete_id,
                        };
                        method = "DELETE"
                        message = slang("Activité_Famille supprimée", "Family_activity deleted")
                        refreshName = "r_activites_familles"
                        break;

                    default:
                        return;
                }

                const response = await fetch(url, {
                    method: method,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify(body)
                });

                if (response.status === 201) {

                    displaySnackbar(message, "Success", "warning")
                    setRefreshPage({ ...refreshPage, [refreshName]: !refreshPage[refreshName] })
                }

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmDelete(false)
    }

    const deleteElement_onclick = (section, id) => {

        setDelete_id(id)
        setConfirmDelete(!confirmDelete)
        setSection(section)
    }

    const ListeOptions = function ({ cs, id }) {

        return <td>
            <button className="btn" onClick={() => {

                switch (cs) {
                    case 1:
                        setCurrent_Selection((s) => ({ ...s, idData: id }))
                        break;

                    case 2:
                        setCurrent_Selection((s) => ({ ...s, idMachine: id }))
                        break;

                    default:
                        break;
                }
            }}>
                <img style={{ height: "30px", width: "auto" }} src="/images/select.svg" alt="select" />
            </button>
            <button className="btn" onClick={() => deleteElement_onclick(cs, id)}>
                <img style={{ height: "35px", width: "auto" }} src="/images/garbage-trash.svg" alt="Corbeille" />
            </button>
            {(confirmDelete && ((id === delete_id) && (cs === section))) &&
                <button className="btn btn-lg fs-4 btn-danger" type="button" onClick={deleteElement}>{t("general.delete") + " ?"}</button>
            }
        </td>
    }

    // Liste des Data dans le dossier actuel
    const ListeData = function () {

        const current_section = 1;

        if (current_selection.idDossierClient === 0) return <></>

        const datas_filtered = datas.filter((d) => {

            return d.idDossierClient === current_selection.idDossierClient
        })

        const table_titles = [
            "id",
            "Denomination",
            t("settings.comment"),
            "Menu",
            "Options"
        ]

        return <>
            <table className="table table-hover">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={table_titles.length}>

                            <NbItems count={datas_filtered.length} />
                        </td>
                    </tr>
                    {datas_filtered.map((data_elt) => (
                        <tr key={data_elt.id}>
                            <th scope="row">{data_elt.id}</th>
                            <td>{data_elt.denomination}</td>
                            <td>{data_elt.comment}</td>
                            <td>
                                <div className="col small text-secondary">
                                    <p className="m-0 p-0">{data_elt.menuEntreprise}</p>
                                    <p className="m-0 p-0">{data_elt.menuDescription?.substring(0, 50)}</p>
                                    <p className="m-0 p-0">{data_elt.menuWhatsapp}</p>
                                    <p className="m-0 p-0">Tarif:{data_elt.menuTarification}</p>
                                </div>
                            </td>

                            <ListeOptions cs={current_section} id={data_elt.id} />
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    // Liste des Machines dans le dossier actuel
    const ListeMachine = function () {

        const current_section = 2;

        if (current_selection.idDossierClient === 0) return <></>

        const machines_filtered = machines.filter((m) => {

            return m.idDossierClient === current_selection.idDossierClient
        })

        const table_titles = [
            "id",
            "computerName",
            t("settings.comment"),
            "Options"
        ]

        return <>
            <table className="table table-hover">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={table_titles.length}>

                            <NbItems count={machines_filtered.length} />
                        </td>
                    </tr>
                    {machines_filtered.map((mach_elt) => (
                        <tr key={mach_elt.id}>
                            <th scope="row">{mach_elt.id}</th>
                            <td>{mach_elt.computerName}</td>
                            <td>{mach_elt.comment}</td>

                            <ListeOptions cs={current_section} id={mach_elt.id} />
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    // Liste des Activites dans le dossier actuel
    const ListeActivite = function () {

        const current_section = 3;

        if (current_selection.idDossierClient === 0) return <></>

        const activites_filtered = activites.filter((a) => {

            return a.idDossierClient === current_selection.idDossierClient
        })

        const table_titles = [
            "id",
            "Denomination",
            "Options"
        ]

        return <>
            <table className="table table-hover">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={table_titles.length}>

                            <NbItems count={activites_filtered.length} />
                        </td>
                    </tr>
                    {activites_filtered.map((act_elt) => (
                        <tr key={act_elt.id}>
                            <th scope="row">{act_elt.id}</th>
                            <td>{act_elt.nomActivite}</td>

                            <ListeOptions cs={current_section} id={act_elt.idActivite} />
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    // Liste des Familles qui se trouvent dans l'activité sélectionnée
    const ListeFamilleGroupe = function () {

        const current_section = 4;

        const getNomFamilleGroupe = function (idFamGroupe) {

            const idx = famille_groupes.findIndex((f) => { return f.idFamilleGroupe === idFamGroupe })

            if (idx === -1) return ""

            return famille_groupes[idx].nomGroupe
        }

        if (current_selection.idDossierClient === 0) return <></>

        const table_titles = [
            "id",
            "Denomination",
            "Data",
            "Options"
        ]

        if (!userHasPriv(props.user, liste_priv.digibobo.doss_clt)) return <></>

        return <>
            <table className="table table-hover">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={table_titles.length}>

                            <NbItems count={activiteFamilles.length} />
                        </td>
                    </tr>
                    {activiteFamilles.map((fam_elt) => (
                        <tr key={fam_elt.idFamilleGroupe}>
                            <th scope="row">{fam_elt.id}</th>
                            <td>{getNomFamilleGroupe(fam_elt.idFamilleGroupe)}</td>
                            <td>{fam_elt.idData}</td>

                            <ListeOptions cs={current_section} id={fam_elt.id} />
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    const TitreSection = function ({ titre }) {
        return <div className="display-6" style={{ marginTop: "50px", marginBottom: "10px" }}>{titre}</div>
    }

    return <>

        {/* Bouton Créer Nouveau Dossier */}

        <button className="btn btn-lg fs-3 btn-success" onClick={() => {
            if (newDossClt.formOpen) {

                setNewDossClt({ ...newDossClt, formOpen: false })
            } else {

                setNewDossClt({ ...newDossClt, formOpen: true })
                setCreateOrModify(true)
            }
        }}>
            {t("settings.btn_doss_create")}
        </button>

        {/* Bouton Modifier Dossier */}

        {(!newDossClt.formOpen && current_selection.idDossierClient > 0) &&

            <button className="btn btn-lg fs-3 btn-outline-success" onClick={() => {
                if (current_selection.idDossierClient === 0) {
                    displaySnackbar(slang("Sélectionner 1 dossier au préalable", "Please select a customer file first"), "impossible", "warning")
                    return
                }
                setNewDossClt({ ...newDossClt, formOpen: true })
                setCreateOrModify(false)
            }}>
                {t("settings.btn_doss_modify")}
            </button>
        }

        {/* Formulaire Création/Modif. Dossier */}

        {newDossClt.formOpen &&

            <div className="col-lg-6 col-md-6 col-12">

                <form onSubmit={(e) => { handleCreateDossierClient(e) }}>
                    <MyInput
                        myName="nomClient"
                        refresh_itemToSave={compose_doss_clt}
                        idGroupe={2}
                        myLabel={createOrModify ? "Nouveau Dossier" : "Modifier Nom"}
                        myType="text"
                    />

                    <MyInput
                        myName="details"
                        refresh_itemToSave={compose_doss_clt}
                        idGroupe={3}
                        myLabel={"Details"}
                        myType="text"
                    />

                    <button className="btn btn-lg fs-4 btn-success mb-3" type="submit">{createOrModify ? t("general.add") : t("general.modify")}</button>
                    <button className="btn btn-lg fs-4 btn-secondary ms-3 mb-3" type="button" onClick={() => setNewDossClt({ ...newDossClt, formOpen: false })}>{t("general.cancel")}</button>
                </form>
            </div>
        }

        <div className="col-11 mx-auto">
            <div className="row">


                {/* Sélecteur de dossier Client */}

                <div className="row">

                    <MySelectSearch
                        classInfo="fs-3 btn btn-outline-primary"
                        myDefaultValue={current_selection.idDossierClient}
                        myName="idDossierClient"
                        refresh_itemToSave={refresh_selection}
                        idGroupe={1}
                        myLabel={t("settings.doss_clt")}
                        data={doss_clts.map((d) => ([d.id, d.id + " - " + d.nomClient]))}
                        func={parseInt}
                    />

                    {/* Détails sur les dossiers */}

                    {current_selection.idDossierClient > 0 &&
                        <div className="row">

                            <div className="col-4">
                                <p>Since {format_date(current_doss_clt.dateDebut)}</p>
                            </div>

                            <div className="col">
                                <p>{current_doss_clt.details}</p>
                            </div>
                        </div>
                    }
                </div>

                {(current_selection.idDossierClient > 0) && <>

                    <div className="btn-group mt-3 mb-3" role="group" aria-label="Navigation buttons">

                        <button type="button" className={"btn btn-lg fs-4 btn-" + (onglet === 1 ? "primary" : "outline-secondary")} onClick={() => handleChoice(1)}>Configuration</button>
                        <button type="button" className={"btn btn-lg fs-4 btn-" + (onglet === 2 ? "primary" : "outline-secondary")} onClick={() => handleChoice(2)}>{t("settings.activites")}</button>
                        <button type="button" className={"btn btn-lg fs-4 btn-" + (onglet === 3 ? "primary" : "outline-secondary")} onClick={() => handleChoice(3)}>Licences</button>

                        {userHasPriv(props.user, liste_priv.digibobo.admin) &&
                            <button type="button" className={"btn btn-lg fs-4 btn-" + (onglet === 4 ? "primary" : "outline-secondary")} onClick={() => handleChoice(4)}>Validation</button>
                        }

                        {(users.length > 0) &&
                            <button type="button" className={"btn btn-lg fs-4 btn-" + (onglet === 5 ? "primary" : "outline-secondary")} onClick={() => handleChoice(5)}>Users</button>
                        }
                    </div>


                    {/* BASE */}

                    {/* Avant c'était le contenu de l'onglet 0 */}


                    {/* CONFIGURATION */}

                    {onglet === 1 && <>

                        {(current_selection.idDossierClient > 0) &&
                            <>
                                <div className="col-11 mx-auto">

                                    {/* DATAS */}

                                    <div className="row">

                                        <TitreSection titre={"Data"} />

                                        <MySelectSearch
                                            classInfo="fs-3 btn btn-outline-secondary"
                                            myDefaultValue={current_selection.idData}
                                            myName="idData"
                                            refresh_itemToSave={refresh_selection}
                                            idGroupe={4}
                                            myLabel="Data"
                                            data={datas.map((d) => ([d.id, d.id + " - "
                                                + (d.denomination ?? "").substring(0, 20)
                                                + " - " + (d.comment ?? "").substring(0, 20)
                                                + (d.idDossierClient === 0
                                                    ? " -- no Doss_Clt --"
                                                    : " - " + (d.nomClient ?? "").substring(0, 20))]))}
                                            func={parseInt}
                                        />

                                        {/* Formulaire de Modification du Data (commentaire et Dossier client subtilement) */}

                                        {current_selection.idData > 0 &&
                                            <form onSubmit={(e) => { handleModifyData(e) }}>
                                                <MyInput
                                                    myName="commentData"
                                                    refresh_itemToSave={refresh_selection}
                                                    idGroupe={5}
                                                    myLabel={t("settings.comment")}
                                                    myType="text"
                                                    myValue={current_selection.commentData}
                                                />

                                                <MyInput
                                                    myName="menuNomEntreprise"
                                                    refresh_itemToSave={refresh_selection}
                                                    idGroupe={6}
                                                    myLabel={t("settings.menuNomEntreprise")}
                                                    myType="text"
                                                    myValue={current_selection.menuNomEntreprise}
                                                />

                                                <MyInput
                                                    myName="menuDescription"
                                                    refresh_itemToSave={refresh_selection}
                                                    idGroupe={7}
                                                    myLabel={"Menu - Description"}
                                                    myType="text"
                                                    myValue={current_selection.menuDescription}
                                                />

                                                <MyInput
                                                    myName="menuWhatsapp"
                                                    refresh_itemToSave={refresh_selection}
                                                    idGroupe={8}
                                                    myLabel={"Menu - WhatsApp"}
                                                    myType="text"
                                                    myValue={current_selection.menuWhatsapp}
                                                />

                                                <MyInput
                                                    myName="menuTarification"
                                                    refresh_itemToSave={refresh_selection}
                                                    idGroupe={9}
                                                    myLabel={t("settings.menuTarification")}
                                                    myType="text"
                                                    myValue={current_selection.menuTarification}
                                                />

                                                <button className="btn btn-lg fs-3 btn-outline-secondary mb-3" type="reset" onClick={() => setCurrent_Selection({ ...current_selection, idData: 0 })}>
                                                    {t("general.cancel")}
                                                </button>

                                                <button className="btn btn-lg fs-3 btn-outline-primary mb-3" type="submit">
                                                    {t("general.modify") + " Data " + current_selection.idData + " - Client " + current_selection.idDossierClient}
                                                </button>

                                            </form>}

                                        <ListeData />
                                    </div>

                                    {/* MACHINES */}

                                    <div className="row">

                                        <TitreSection titre={t("settings.machines")} />

                                        <MySelectSearch
                                            classInfo="fs-3 btn btn-outline-secondary"
                                            myDefaultValue={current_selection.idMachine}
                                            myName="idMachine"
                                            refresh_itemToSave={refresh_selection}
                                            idGroupe={10}
                                            myLabel={t("settings.machine")}
                                            data={machines.map((m) => ([m.id, m.id + " - " + m.computerName + " " + (m.comment ?? "").substring(0, 20) + (m.idDossierClient === 0 ? " -- no Doss_Clt --" : " - " + (m.nomClient ?? "").substring(0, 20))]))}
                                            func={parseInt}
                                        />

                                        {/* Formulaire de Modification du commentaire Machine */}

                                        {current_selection.idMachine > 0 &&
                                            <form onSubmit={(e) => { handleModifyMachine(e) }}>
                                                <MyInput
                                                    myName="commentMachine"
                                                    refresh_itemToSave={refresh_selection}
                                                    idGroupe={11}
                                                    myLabel={t("settings.comment")}
                                                    myType="text"
                                                    myValue={current_selection.commentMachine}
                                                />

                                                <button className="btn btn-lg fs-3 btn-outline-secondary mb-3" type="reset" onClick={() => setCurrent_Selection({ ...current_selection, idMachine: 0 })}>
                                                    {t("general.cancel")}
                                                </button>

                                                <button className="btn btn-lg fs-3 btn-outline-primary mb-3" type="submit">
                                                    {t("general.modify") + " " + t("settings.machine") + " " + current_selection.idMachine + " - Client " + current_selection.idDossierClient}
                                                </button>
                                            </form>}

                                        <ListeMachine />

                                    </div>

                                    {/* LOG */}

                                    {(current_selection.idMachine > 0) &&
                                        <div className="mx-2" style={{ marginTop: "50px" }}>

                                            <TitreSection titre={"Log" + slang(" Machine ", " Computer ") + current_selection.idMachine} />

                                            <ResponsiveTable
                                                itemsPerPage={10}
                                                listOfItems={logActions}
                                                table_titles={["action", "date", "version"]}
                                                Row={({ elt }) => {

                                                    return <tr
                                                        key={elt.id}
                                                        onClick={() => { }}
                                                    >
                                                        <>
                                                            <td className="text-center">{elt.logAction}</td>
                                                            <td className="text-center">{format_date(elt.dateRecente)}</td>
                                                            <td className="text-center">{elt.versionLogiciel}</td>
                                                        </>
                                                    </tr>
                                                }}
                                            />
                                        </div>
                                    }

                                </div>
                            </>}
                    </>}

                    {/* ACTIVITES */}

                    {onglet === 2 && <>
                        {(current_selection.idDossierClient > 0) &&
                            <>
                                <div className="col-11 mx-auto">

                                    {/* ACTIVITES */}

                                    <div className="row">

                                        <TitreSection titre={t("settings.activites")} />

                                        <MySelectSearch
                                            classInfo="fs-3 btn btn-outline-secondary"
                                            myDefaultValue={current_selection.idActivite}
                                            myName="idActivite"
                                            refresh_itemToSave={refresh_selection}
                                            idGroupe={12}
                                            myLabel={t("settings.activite")}
                                            data={activites.map((a) => ([a.id, a.id + " - " + a.nomActivite]))}
                                            func={parseInt}
                                        />

                                        <ListeActivite />

                                        {/* Formulaire de Création d'une nouvelle activite */}

                                        {current_selection.idDossierClient > 0 &&
                                            <form onSubmit={(e) => { handleCreateActivite(e) }}>
                                                <MyInput
                                                    myName="nomActivite"
                                                    refresh_itemToSave={refresh_selection}
                                                    idGroupe={13}
                                                    myLabel={t("general.newF") + " " + t("settings.activite")}
                                                    myType="text"
                                                    myValue={current_selection.nomActivite}
                                                />

                                                <button className="btn btn-lg fs-4 btn-outline-primary mb-3" type="submit">{t("settings.btn_create_act") + " " + current_selection.nomActivite}</button>

                                            </form>}
                                    </div>

                                    {/* BB_FAMILLE_GROUPES */}

                                    <div className="row mb-3 pb-3">
                                        <div className="col">
                                            <TitreSection titre={t("settings.act_familles") + " " + current_selection.idActivite} />

                                            {current_selection.idActivite > 0 &&
                                                <>

                                                    <ListeFamilleGroupe />

                                                    <MySelectSearch
                                                        classInfo="fs-3 btn btn-outline-secondary"
                                                        myDefaultValue={current_selection.idFamilleGroupe}
                                                        myName="idFamilleGroupe"
                                                        refresh_itemToSave={refresh_selection}
                                                        idGroupe={14}
                                                        myLabel={slang("Famille", "Family")}
                                                        data={famille_groupes.map((f) => ([f.idDataSource * 1000 + f.idFamilleGroupe, f.idFamilleGroupe + " - " + f.nomGroupe]))}
                                                        func={parseInt}
                                                    />

                                                    <button className="btn btn-lg fs-4 btn-outline-primary mb-3" onClick={addActiviteFamille}>{t("settings.liaison_famille")}</button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>}

                    {/* LICENCES */}

                    {onglet === 3 && <>
                        <SettingsLicences user={props.user} parent_idDossierClient={current_selection.idDossierClient} switchValidation={false} />
                    </>}

                    {onglet === 4 && <>
                        <SettingsLicences user={props.user} parent_idDossierClient={current_selection.idDossierClient} switchValidation={true} />
                    </>}

                    {/* USERS */}

                    {(onglet === 5 && users.length > 0) && <>
                        <ListeUsersAccesCloud current_user={props.user} users={users} refreshUser={refreshUser} setRefreshUser={setRefreshUser} />
                    </>}
                </>}
            </div >
            <MyScrollButton />
        </div >
    </>
}

export default SettingsDossier;