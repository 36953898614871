
import InterventionForm from "./InterventionForm";
import AdminPage from "../AdminPage";
import { format_date, format_date_nohour, kliste_types_intervention, slang } from "../Utils";
import Header from "../Header";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MySpinningSelectSearch } from "../FormElements";


const InterventionAdmin = function (props) {

    const { t } = useTranslation();

    const [filtCustomer, setFiltCustomer] = useState(0)

    const table_titles = [
        "id",
        "type - date",
        slang("contenu", "content"),
        "details",
        "options"
    ];

    return <div>

        <Header user={props.user} />

        <div className="col-lg-9 mx-auto">


            <div className="col-8" style={{ margin: "50px 10px 0 10px" }}>

                <MySpinningSelectSearch
                    spinnerText={slang("Chargement Dossiers Clients", "Loading Customers' Folders")}
                    searchRefreshItemToSave={(myName, myValue) => { setFiltCustomer(parseInt(myValue)) }}
                    searchName="idDossierClient"
                    searchLabel={slang("Dossier Client", "Customer's Folder")}
                    searchEndPoint="doss_clts_for_select"
                    searchIdGroup={1}
                />
            </div>

            <AdminPage
                title={slang("Les Interventions", "Interventions")}
                endpoint="intervention"
                CustomForm={InterventionForm}
                table_titles={table_titles}
                filt1="idDossierClient"
                filt2="content"
                filt3="idData"
                itemsPerPage={5}
                globalFilters={[
                    // titre du filtre, field, function qui renvoie true si l'élt évalué doit s'afficher

                    [t("general.filter") + slang(" Client", " Customer") + " " + filtCustomer, "idDossierClient", (elt) => (elt === filtCustomer)],
                ]}
                RowContent={({ elt }) => {
                    return <>
                        <th scope="row">{elt.id}</th>
                        <td>
                            {kliste_types_intervention[elt.typeIntervention][1]}
                            <p>{format_date_nohour(elt.dateHeure)}</p>
                        </td>
                        <td>{elt.content}</td>
                        <td className="fs-4">
                            <p className="m-0">Modif.{format_date(elt.dateModification)}</p>
                            <p className="m-0">Creation.{format_date(elt.dateCreation)}</p>
                            <p className="m-0">data {elt.idData + " " + elt.denomination}</p>
                            <p className="m-0">doss {elt.idDossierClient + " " + elt.nomClient}</p>
                            <p className="text-primary">{elt.username + " (" + elt.idUser + ")"}</p>
                        </td>
                    </>
                }}
            />

        </div>
    </div>
}

export default InterventionAdmin;