import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormTitleGeneric, handleSubmitGeneric, format_date, slang, kliste_types_intervention } from '../Utils';
import { MyInput, MySelect, MySpinningSelectSearch } from '../FormElements';

const InterventionForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [itemToSave, setItemToSave] = useState({
        idDossierClient: bool_modify ? current_item.idDossierClient : 0,
        idData: bool_modify ? current_item.idData : 0,
        idUser: bool_modify ? current_item.idUser : 0,
        typeIntervention: bool_modify ? current_item.typeIntervention : 0,
        content: bool_modify ? current_item.content : "",
        dateHeure: (!bool_modify || !current_item.dateHeure)
            ? format_date(Date.now())
            : format_date(current_item.dateHeure),
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }

    return <>

        <FormTitleGeneric bool_modify={bool_modify}
            name={current_item?.content?.substring(50)}
            id={current_item?.id}
        />

        <form className="col-lg-6 col-md-8 col-sm-10 mx-auto mb-3"
            onSubmit={(e) => handleSubmitGeneric(e, "intervention", itemToSave, current_item?.id, refresh_list, bool_modify)}>

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Dossiers Clients", "Loading Customers' Folders")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idDossierClient"
                searchLabel={slang("Dossier Client", "Customer's Folder")}
                searchEndPoint="doss_clts_for_select"
                searchIdGroup={1}
            />

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Data", "Loading Data")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idData"
                searchLabel="Data"
                searchEndPoint="data_for_select"
                searchIdGroup={2}
            />

            <MySpinningSelectSearch
                spinnerText={slang("Chargement utilisateurs", "Loading Users")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idUser"
                searchLabel={slang("Utilisateurs", "Users")}
                searchEndPoint="users_for_select"
                searchIdGroup={3}
            />

            <MySelect
                myName="typeIntervention"
                refresh_itemToSave={refresh_itemToSave}
                myLabel="Type"
                data={kliste_types_intervention}
                func={parseInt}
                idGroupe={4}
            />

            <MyInput
                myName="content"
                myValue={itemToSave.content}
                myLabel={slang("Contenu", "Content")}
                myType="text"
                idGroupe={5}
                refresh_itemToSave={refresh_itemToSave}
            />

            <MyInput
                myName="montant"
                myValue={itemToSave.content}
                myLabel={slang("Montant", "Amount")}
                myType="number"
                idGroupe={6}
                refresh_itemToSave={refresh_itemToSave}
                func={parseInt}
            />

            <MyInput
                myName="dateHeure"
                refresh_itemToSave={refresh_itemToSave}
                myLabel="Date"
                myType="date"
                idGroupe={6}
            />


            {/* Validation */}

            <button className="btn btn-lg btn-success fs-3" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>

        </form>

    </>
}

export default InterventionForm;