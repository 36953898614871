import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { MyInput, MySelect, MySelectSearch } from '../FormElements';
import { displaySnackbar, slang } from '../Utils';

const proxyUrl = require("../../../package.json").proxy;

const types_licences_dossier = [
    [1, "Licence Comptoir"],
    [2, "Licence Data Cloud"],
    [3, "Licence Menu"],
    // [3, "Licence Cloud User"], Plus nécessaire depuis que la date d'accès est directement inscrite dans DB_User
    // [4, "Licence Mails"], 7jan24 : Plus nécessaire, ça alourdit la tâche pour rien, autant gérer juste avec la licence Cloud
    // [5, "Assist distance"], 7jan24 : bof je noterai ça différemment
    // [6, "Assist sur Site"], 7jan24 : bof je noterai ça différemment
]


function SettingsLicenceForm({ lic, user, parent_idDossierClient, refreshLicences }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(lic);

    const [datas, setDatas] = useState([])
    const [machines, setMachines] = useState([])

    const [licToSave, setLicTosave] = useState({
        typeLicence: bool_modify ? lic.typeLicence : 0,
        idMachine: bool_modify ? lic.idMachine : 0,
        idData: bool_modify ? lic.idData : 0,
        idDossierClient: bool_modify ? lic.idDossierClient : parent_idDossierClient,
        comment: bool_modify ? lic.comment : '',
        dateheureDebut: bool_modify ? lic.dateheureDebut : null,
        nbJours: bool_modify ? lic.nbJours : 0,
        montant: bool_modify ? lic.montant : 0,
        idResp: user.id,
        validated: false,
    })

    // Fetch Data
    useEffect(() => {

        if (licToSave.idDossierClient === 0) return

        const fetchData = async () => {
            try {
                const response = await fetch(proxyUrl + '/datas/' + licToSave.idDossierClient);
                const data = await response.json();
                setDatas(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [licToSave.idDossierClient])

    // Fetch Machines
    useEffect(() => {

        if (licToSave.idDossierClient === 0) return

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/machines/' + licToSave.idDossierClient);
                const data = await response.json();
                setMachines(data);

            } catch (error) {
                console.error(error);
            }
        })();
    }, [licToSave.idDossierClient])

    // Modification et Création de Licence
    const handleSubmit = async (e) => {

        e.preventDefault();

        if (licToSave.typeLicence === 0) {
            displaySnackbar(slang("Le type de licence est requis", "Please the licence is required"), slang("Remplissage incomplet", "Fill the form"), "warning")
            return
        }

        if (licToSave.idMachine === 0 )licToSave.idMachine = null
        if (licToSave.idData === 0 )licToSave.idData = null

        const url = proxyUrl + "/licence" + (bool_modify ? "/" + lic.id : "")

        try {

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({ ...licToSave, running: true }),
            })

            if (response.status === 201) {

                displaySnackbar(bool_modify ? slang("Licence modifiée", "Licence changed successfully") : slang("Licence créée", "Licence created successfully"), slang("Succès", "Success"), "success")
                refreshLicences()
            }

        } catch (error) {
            console.error(error);
        }
    };

    const refresh_licToSave = function (myName, myValue) {

        let newComp = {}

        // ça c'est la liste de ceux qui n'ont pas besoin de parseInt
        if (-1 === ["validated", "dateheureDebut", "comment"].findIndex(v=> {return myName===v})){

            newComp = { ...licToSave, [myName]: isNaN(myValue) ? 0 : myValue }
        } else {
            newComp = { ...licToSave, [myName]: myValue }
        }

        setLicTosave(newComp);
    }

    return (
        <div >
            <div className="row">
                {bool_modify ?
                    <h2>{t("shop.modify") + " Licence " + lic.id}</h2>
                    :
                    <h2>Creation</h2>
                }

                <form onSubmit={handleSubmit}>

                    <div className="col-10 mx-auto">

                        <MySelect
                            myName="typeLicence"
                            refresh_itemToSave={refresh_licToSave}
                            idGroupe={5}
                            myLabel="typeLicence"
                            data={types_licences_dossier}
                            func={parseInt}
                        />

                        {((licToSave.typeLicence === 2) || (licToSave.typeLicence === 3)) &&
                            <MySelectSearch
                                classInfo="fs-3 btn btn-outline-secondary"
                                myDefaultValue={licToSave.idData}
                                myName="idData"
                                refresh_itemToSave={refresh_licToSave}
                                idGroupe={3}
                                myLabel="Data"
                                data={datas.map((d) => ([d.id, d.id + " - " + (d.denomination ?? "").substring(0, 25) + " - " + (d.comment ?? "").substring(0, 30)]))}
                                func={parseInt}
                            />
                        }

                        {(licToSave.typeLicence === 1) &&
                            <MySelectSearch
                                classInfo="fs-3 btn btn-outline-secondary"
                                myDefaultValue={licToSave.idMachine}
                                myName="idMachine"
                                refresh_itemToSave={refresh_licToSave}
                                idGroupe={4}
                                myLabel="Machines"
                                data={machines.map((m) => ([m.id, m.id + " - " + m.computerName + " " + (m.comment ?? "").substring(0, 30)]))}
                                func={parseInt}
                            />
                        }

                    </div>

                    {(licToSave.idData + licToSave.idMachine > 0) &&
                        <div className="col-6 mx-auto">
                            <MyInput
                                myName="dateheureDebut"
                                refresh_itemToSave={refresh_licToSave}
                                idGroupe={6}
                                myLabel="dateheureDebut"
                                myType="date"
                            />

                            <MyInput
                                myName="nbJours"
                                refresh_itemToSave={refresh_licToSave}
                                idGroupe={7}
                                myLabel="nbJours"
                                myType="number"
                                func={parseInt}
                                myValue={licToSave.nbJours}
                            />

                            <button className="btn btn-lg btn-outline-secondary mb-3" type="button" onClick={() => {
                                if (licToSave.nbJours > 0) refresh_licToSave("nbJours", licToSave.nbJours * -1)
                            }
                            }>nbJours Negative</button>

                            <MyInput
                                myName="montant"
                                refresh_itemToSave={refresh_licToSave}
                                idGroupe={8}
                                myLabel="montant"
                                myType="number"
                                func={parseInt}
                            />

                            <MyInput
                                myName="comment"
                                refresh_itemToSave={refresh_licToSave}
                                idGroupe={9}
                                myLabel="details"
                                myType="text"
                            />

                        </div>
                    }

                    <button className="btn btn-lg fs-4 btn-primary" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>
                </form>
            </div>
        </div>
    );
}

export default SettingsLicenceForm;