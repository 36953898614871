import Header from "../Header";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { slang } from "../Utils";

const proxyUrl = require("../../../package.json").proxy;

const files_list = [
    {
        filename: "Setup_BreeBar_6.2.exe",
        descriptionFR: "Fichier d'installation du logiciel de caisse Breebar",
        descriptionEN: "Setup of cashbox management software Breebar",
        size: "238 Mo",
        serverPath: "/downloads/breebar",
    },
    {
        filename: "BreeBar_PSF.exe",
        descriptionFR: "Fichiers additionnels pour la prise de commande via réseau local LAN ou Wifi de Breebar",
        descriptionEN: "Additionnal Setup files for the local remote ordering of Breebar",
        size: "279 Mo",
        serverPath: "/downloads/psf",
    },
    {
        filename: "BB_maj_5.4.sav",
        descriptionFR: "Mise à jour cumulative 5.4 Breebar",
        descriptionEN: "Cumulative update 5.4 Breebar",
        size: "49.5 Mo",
        serverPath: "/downloads/bb_maj_5_4",
    },
    {
        filename: "BB_maj_6.2.sav",
        descriptionFR: "Mise à jour cumulative 6.2 Breebar",
        descriptionEN: "Cumulative update 6.2 Breebar",
        size: "47.9 Mo",
        serverPath: "/downloads/bb_maj_6_2",
    },
]

const DownloadPage = function (props) {

    const { t } = useTranslation();

    const downloadFile = (url, filename) => {
        fetch(proxyUrl + url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
                // window.location.href = response.url;
            });
    }

    return (
        <div>
            <Header user={props.user} />
            <div className="body m-3 p-3">
                <h1 className="m-3 py-3">{slang("Téléchargements", "Downloads")}</h1>

                <table className="table table-hover m-3 p-3">

                    <thead>
                        <tr>
                            <td>FileName</td>
                            <td>Description</td>
                            <td>Size</td>
                            <td>Download</td>
                        </tr>
                    </thead>
                    <tbody className="fs-3">
                        {
                            files_list.map((f, i) => {
                                return <tr key={i}>

                                    <td>
                                        <p>{f.filename}</p>
                                    </td>
                                    <td>
                                        <p>{slang(f.descriptionFR, f.descriptionEN)}</p>
                                    </td>
                                    <td>
                                        <p className="text-nowrap">{f.size}</p>
                                    </td>
                                    <td>
                                        <button className="btn btn-lg btn-dark" onClick={() => downloadFile(f.serverPath, f.filename)}>{t("general.download")}</button>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>

            </div>


            <Footer />
        </div>
    )
}

export default DownloadPage;