import React from 'react';
import ReactDOM from 'react-dom/client';

import App from "./App"
import { Suspense } from 'react';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<Suspense fallback={
			<div className="spinner-border" role="status">
				<span className="visually-hidden">Loading...</span>
			</div>
		}>
			<App />
		</Suspense>
	</React.StrictMode>
);