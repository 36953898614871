import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { displaySnackbar, EmptyComponent, fcomp, isEmptyObject, MyInputSearch, slang } from "./Utils";
import { ResponsiveTable } from "./ResponsiveTable";
const proxyUrl = require("../../package.json").proxy;

// titre du filtre, field, statut du filtre,
// function qui renvoie true si l'élt évalué doit s'afficher

const AdminPage = function ({ title, endpoint, CustomForm, itemsPerPage,
    RowContent, table_titles, filt1, filt2, filt3, parent_criteria, DetailsSection, globalFilters = [] , LastRow= EmptyComponent}) {

    const { t } = useTranslation();

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [detailElt, setDetailElt] = useState({})

    const [filters, setFilters] = useState([])

    const handleClick = function (elt) {

        if (DetailsSection === undefined) {

            selectElt(elt)
        } else {

            setDetailElt(elt);
        }
    }

    const [data, setData] = useState([])
    const [dataFilt, setDataFilt] = useState([])
    const [item_selected, setItem_selected] = useState(null)

    const [elt_id, setElt_id] = useState(0); // pour savoir quel élément supprimer

    const [formOpen, setFormOpen] = useState(false);

    const [refreshList, setRefreshList] = useState(false);

    const toggleForm = () => {
        if (formOpen) setItem_selected(null)
        setFormOpen(!formOpen);
    }

    const refresh_list = () => {
        setFormOpen(false)
        setItem_selected(null)
        setRefreshList(!refreshList)
    }

    const selectElt = (elt) => {

        setConfirmDelete(false)
        setItem_selected(elt);
        setFormOpen(true);
    }

    // fetch Data
    useEffect(() => {

        let final_endpoint = endpoint;

        if (parent_criteria !== null && parent_criteria !== undefined) {
            final_endpoint += "/" + Object.keys(parent_criteria)[0] + "/" + Object.values(parent_criteria)[0];
        }

        const fetchComposantes = async () => {
            try {
                const response = await fetch(proxyUrl + '/' + final_endpoint);
                const data = await response.json();
                setData(data);
                setDataFilt(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchComposantes();

    }, [endpoint, refreshList, parent_criteria])

    const deleteElt = () => {

            (async () => {
                try {
                    const response = await fetch(proxyUrl + '/' + endpoint + '/' + elt_id, {
                        method: 'DELETE',
                        headers: {
                            "Content-Type": "application/json"
                        },
                        credentials: "include",
                    });

                    if (response.status === 201) 
                    {
                        refresh_list();
                    } else {
                        
                        displaySnackbar(slang("Modification impossible", "An error occured"), "ERROR", "success")
                    }

                } catch (error) {
                    console.error(error);
                }
            })();

        setConfirmDelete(false)
    }

    const deleteComposante_onclick = (id) => {
        setElt_id(id)
        setConfirmDelete(!confirmDelete)
    }

    // Gestion des filtres

    const updateFilter = function (i) {
        if (!Object.hasOwn(filters, i)) {

            setFilters({ ...filters, [i]: true })
        } else {

            setFilters({ ...filters, [i]: !filters[i] })
        }

    }

    const getDataFiltered = useCallback(function () {

        if (isEmptyObject(filters)) return

        let newData = data

        Object.keys(filters).forEach(k => {
            if (filters[k]) {
                const f = globalFilters[k]
                newData = newData.filter((d) => {

                    // J'exécute ici la fonction indiquée dans le tableau de filtres passé
                    return f[fcomp.func](d[f[fcomp.field]])
                })
            }
        });

        setDataFilt(newData)

    }, [data, filters, globalFilters])

    useEffect(() => {
        getDataFiltered()
    }, [getDataFiltered])

    const reset_filters = function () {

        setFilters({})
    }


    const GetSectionDetail = function ({ detailElt }) {

        if (DetailsSection === undefined) {
            return <></>
        }

        return <DetailsSection elt={detailElt} />
    }

    let compose_current_item = Object.assign({}, item_selected, parent_criteria)

    if (isEmptyObject(compose_current_item)) compose_current_item = null

    return <div className="body fs-5">

        <h1 className="py-3 display-2">{title}</h1>

        <button className={"btn btn-lg fs-3 mb-3 btn-" + (formOpen ? "outline-info" : "info")} onClick={() => toggleForm()}>
            {(formOpen ? t("general.back_to_list") : t("general.add")).toUpperCase()}
        </button>

        {formOpen && <CustomForm current_item={compose_current_item} refresh_list={refresh_list} />}

        {!formOpen && <>
            <br />

            <div className="btn-group mt-3 mb-3" role="group" aria-label="Navigation buttons">
                {
                    globalFilters.map((filt, i) => {

                        return <button
                            key={i}
                            type="button"
                            className={"btn btn-lg fs-3 mb-3 btn-" + (filters[i] ? "success" : "outline-dark")}
                            onClick={() => updateFilter(i)}
                        >
                            {filt[fcomp.title]}
                            {filters[i] ? " ON" : ""}
                        </button>
                    })
                }
            </div>

            <MyInputSearch
                myLabel=""
                idGroupe={100}
                items={data}
                setItemsFiltered={(items) => {
                    reset_filters()
                    setDetailElt({})
                    setDataFilt(items)
                }}
                filt1={filt1}
                filt2={filt2}
                filt3={filt3}
            />

            <ResponsiveTable
                itemsPerPage={itemsPerPage}
                listOfItems={dataFilt}
                table_titles={table_titles}
                LastRow={LastRow}
                Row={({ elt }) => {
                    return <tr
                        key={elt.id}
                        onClick={() => handleClick(elt)}
                    >
                        <RowContent elt={elt} />
                        <td>
                            <button className="btn" onClick={() => {
                                selectElt(elt)
                                window.scrollTo(0, 0)
                            }}>
                                <img style={{ height: "45px", width: "auto" }} src="/images/pencil.svg" alt="Crayon" />
                            </button>

                            <button className="btn" onClick={(e) => {
                                e.stopPropagation()
                                deleteComposante_onclick(elt.id)
                            }
                            }>
                                <img style={{ height: "45px", width: "auto" }} src="/images/garbage-trash.svg" alt="Corbeille" />
                            </button>
                            {(confirmDelete && (elt.id === elt_id)) &&
                                <button className="btn btn-danger fs-4"
                                    type="button"
                                    onClick={(e)=>{
                                        e.stopPropagation()
                                        deleteElt()
                                        }}>
                                    Confirm Delete
                                </button>
                            }
                        </td>
                    </tr>
                }}
            />

            <GetSectionDetail detailElt={detailElt} />
        </>}
    </div>
}

export default AdminPage;