import React from 'react';
import { format_date } from './Utils';

export default function MyToast() {

    return (
            <div className="toast-container" style={{ backgroundColor: "transparent", position: "fixed", top: "10px", left: "5px" }}>
                <div id="myToast" className="toast text-bg-dark my-3" role="alert" aria-live="assertive" aria-atomic="true" data-bs-dismiss="toast">
                    <div className="toast-header">
                        <strong id="toast_title" className="me-auto">my_title</strong>
                        <small>{format_date(Date())}</small>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div id="toast_message" className="toast-body fs-4">
                        my_message
                    </div>
                </div>
            </div>
    );
}
