
export default function MenuFamilies({ infoIdFamille, changeIdFamille, infoIdFamilleGroupe, familles }) {

    const status_colors = ["Tomato", "SeaShell"];

    const ButtonGroup = function ({ id, nom }) {

        return <button
            className="btn ticket border border-warning fs-3 m-1"
            onClick={() => {
                if (infoIdFamille === -2) {

                    changeIdFamille(id)
                } else {

                    changeIdFamille(-2)
                }
            }}
            style={{ backgroundColor: status_colors[(infoIdFamille === id) ? 0 : 1] }}>

            <p>{nom}</p>

        </button>
    }

    return (
        <div className="mx-3 text-center">

            {(infoIdFamille === -2) && <div>
                {familles.map((famille, idx) => {

                    const idFamGrp = (famille.idFamilleGroupe === undefined) ? 0 : famille.idFamilleGroupe

                    if (infoIdFamilleGroupe !== idFamGrp) return null

                    return <ButtonGroup key={idx} id={famille.IdFamille} nom={famille.NomFamille} />
                })}
            </div>
            }

            {
                (infoIdFamille !== -2) && <span>

                    {familles.map((famille, idx) => {

                        if (infoIdFamille !== famille.IdFamille) return null

                        return (
                            <button
                                key={idx}
                                className="btn ticket border border-warning fs-3 m-1"
                                onClick={() => changeIdFamille(-2)}
                                style={{ backgroundColor: status_colors[(infoIdFamille === famille.IdFamille) ? 0 : 1] }}>

                                <p>{famille.NomFamille}</p>

                            </button>
                        );
                    })}

                </span>
            }



        </div>

    );
}