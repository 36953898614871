import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import defaultPlaceholderImg from "../images/default-placeholder.png";
import { format_date_nohour } from '../Utils';

const proxyUrl = require("../../../package.json").proxy;

const PostPage = (props) => {

    const [post, setPost] = useState(null);

    const { current_id } = useParams();

    useEffect(() => {
        fetch(proxyUrl + "/get_post/" + current_id)
            .then(response => response.json())
            .then(data => setPost(data[0]));
    }, [current_id]);    

    return (
        <div>
            <Header user={props.user} />

            <div className="body fs-4 justify-content-center text-center" style={{ margin:"100px"}}>

                {post && (
                    <div className="col-10 mx-auto">
                        <h1 className="my-3 py-3">{post.title}</h1>
                        <img className="rounded my-3 py-3" style={{ maxHeight: "400px", maxWidth: "400px" }} src={proxyUrl + "/uploads/0/" + current_id + "-4.jpg"} onError={(e) => { e.target.onerror = null; e.target.src = defaultPlaceholderImg }} alt="Full Size" />
                        <p className="text-primary my-3 py-3 fs-3">{format_date_nohour(post.createdAt)}</p>
                        <p className="text-start css-fix-linebreak my-3 py-3 fs-3">{post.content}</p>
                    </div>
                )}

            </div>

            <Footer />
        </div>
    );
};

export default PostPage;