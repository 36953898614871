import { useEffect, useState } from "react";
import { Article } from "./ShopArticle";
import Header from "../Header";
import Footer from "../Footer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const proxyUrl = require("../../../package.json").proxy;

export default function ShopProduct(props) {

    const { t } = useTranslation()

    const { idProduct } = useParams();

    const [product,
        setProduct] = useState({})

    // fetch 1 ShopProduct
    useEffect(() => {

        const fetchShopProducts = async () => {
            try {
                const response = await fetch(proxyUrl + '/shop_product/' + idProduct);
                const data = await response.json();
                setProduct(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchShopProducts();

    }, [idProduct])

    return <div>

        <Header user={props.user} />

        <div className="m-3 p-3 text-center">

            <a className="m-3 fs-4 btn btn-lg btn-outline-secondary align-middle" href="/shop_main">
                {t("shop.back")}
            </a>

            <div className="row justify-content-center">

                <Article sp={product} full_description_text={true} />
            </div>
        </div>

        <Footer />
    </div>
}