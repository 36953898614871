import { useState, useEffect } from 'react';

const proxyUrl = require("../../../package.json").proxy;

export default function MenuGroups({ infoIdFamilleGroupe, changeIdFamilleGroupe, changeIdFamille, idData }) {

    const [groupsList,
        setGroupsList] = useState({ groups: [] });

    const status_colors = ["Tomato", "white"];

    // FETCH the groups list
    useEffect(() => {

        const fetchGroups = async () => {

            fetch(proxyUrl + "/groupes_menu/" + idData)
                .then(response => response.json())
                .then(result => {
                    return result.sort(function (a, b) {
                        return a.nomGroupe.localeCompare(b.nomGroupe);
                    });
                })
                .then(result => {

                    if (result.length <= 1) {
                        
                        if (result.length===0){
                         
                            changeIdFamilleGroupe(0)
                        } else {

                            changeIdFamilleGroupe(result[0].idFamilleGroupe)
                        }
                    } else {

                        setGroupsList({ groups: result });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        };
        fetchGroups();
    }, [changeIdFamilleGroupe, idData]);

    const ButtonGroup = function ({ id, nom }) {

        return <button
            className="btn btn-lg m-2 p-2 fs-3 ticket border border-warning"
            
            onClick={() => {
                if (infoIdFamilleGroupe === -2) {

                    changeIdFamilleGroupe(id)
                } else {

                    changeIdFamille(-2)
                    changeIdFamilleGroupe(-2)
                }
            }}
            style={{ backgroundColor: status_colors[(infoIdFamilleGroupe === id) ? 0 : 1] }}>

            <p>{nom.toUpperCase()}</p>

        </button>
    }

    if (groupsList.groups.length <= 1) return <></>

    return <div className="mx-3 text-center">

        {(infoIdFamilleGroupe === 0) && <ButtonGroup key={-1} id={0} nom={"AUTRES"} />}

        {(infoIdFamilleGroupe === -2) && <div>

            <ButtonGroup key={-1} id={0} nom={"AUTRES"} />

            {groupsList.groups.map((groupe, idx) => {

                return <ButtonGroup key={idx} id={groupe.idFamilleGroupe} nom={groupe.nomGroupe} />
            })}
        </div>
        }

        {
            (infoIdFamilleGroupe !== -2) && <span>

                {groupsList.groups.map((groupe, idx) => {

                    if (infoIdFamilleGroupe !== groupe.idFamilleGroupe) return null

                    return <ButtonGroup key={idx} id={groupe.idFamilleGroupe} nom={groupe.nomGroupe} />
                })}

            </span>
        }

</div>
}


