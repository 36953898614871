import { useTranslation } from "react-i18next";
import { OrderForm, digitGroup } from "../Utils";
import i18next from "i18next";

const proxyUrl = require("../../../package.json").proxy;

export const Article = function ({ sp, full_description_text = false }) {

    const { t } = useTranslation()

    const SHORT_DESCRIPTION_LENGTH = 70;

    const img_path = proxyUrl + "/uploads/0/" + sp.id + "-5.jpg"

    const get_description = function () {

        let description

        description = (i18next.language === "fr" ? sp.description : sp.descriptionEN)

        const trois_points = ((description?.length > 50) && !full_description_text)

        if (description && !full_description_text && (sp.id !== 0)) {
            // cas de l'article spécial 0 qui correspond à la publicité de Breebar

            description = description.substring(0, SHORT_DESCRIPTION_LENGTH)
        }

        return <span>
            {description}
            {trois_points && <a className="text-decoration-none" href={"/shop_product/" + sp.id}>{" ..." + t("shop.know_more")}</a>}
        </span>
    }

    const ArticleImg = () => {

        return <img className="card-img-top my-1" style={{ height: "auto", width: "50%" }} src={img_path} alt="Card cap" />
    }

    return (
        <div>
            <div className="card text-center border-primary" style={{ marginBottom: "20px", fontSize: "1.4em" }}>

                <div className="card-header border-primary">
                    <h5 className="fs-3" style={{ marginTop: "20px" }}>{(i18next.language === "fr" ? sp.denomination : sp.denominationEN)}</h5>
                </div>

                <div className="card-body">

                    <h3 className="card-title">{digitGroup(sp.price)}<small> FCFA</small></h3>

                    {!full_description_text &&
                        <a className="text-decoration-none" href={"/shop_product/" + sp.id}>
                            <ArticleImg />
                        </a>
                    }

                    {full_description_text && <ArticleImg />}

                    <div className="fs-3">{get_description()}</div>

                </div >

                <div className="card-footer">
                    <OrderForm total={sp.price} content={{ ...sp }} />
                </div>
            </div >
        </div>
    )
}