import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ProcesseurForm from "./ProcesseurForm";
import Header from "../Header";
const proxyUrl = require("../../../package.json").proxy;

const ProcesseurAdmin = function (props) {

    // proc = processeur ordi

    const { t } = useTranslation();

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [procs, setProcs] = useState([])
    const [proc_selected, setProc_selected] = useState(null)

    const [proc_id, setProc_id] = useState(0); // pour savoir quelle composante supprimer

    const [formOpen, setFormOpen] = useState(false);

    const [refreshList, setRefreshList] = useState(false);

    const toggleForm = () => {
        if (formOpen) setProc_selected(null)
        setFormOpen(!formOpen);
    }

    const refresh_list = () => {

        setRefreshList(!refreshList);
        setFormOpen(false)
        setProc_selected(null)
    }

    const selectProc = (comp) => {

        setConfirmDelete(false)
        setProc_selected(comp);
        setFormOpen(true);
    }

    // fetch Proc types
    useEffect(() => {

        const fetchProcs = async () => {
            try {
                const response = await fetch(proxyUrl + '/proc');
                const data = await response.json();
                setProcs(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchProcs();

    }, [refreshList])

    const deleteProc = () => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/proc', {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({ id: proc_id })
                });

                if (response.status === 201) window.location.reload();

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmDelete(false)
    }

    const deleteProc_onclick = (name, id) => {
        setProc_id(id)
        setConfirmDelete(!confirmDelete)
    }

    const RenderComposantes = () => {
        const table_titles = [
            "id",
            "A",
            t("proc.name"),
            "Options"
        ]

        return <table className="table table-hover">
            <thead>
                <tr>
                    {table_titles.map((title_elt, i) => (
                        <th key={i} scope="col">{title_elt}</th>
                    ))}
                    <th key={0} scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {procs.map((proc_elt) => (
                    <tr key={proc_elt.id}>
                        <th scope="row">{proc_elt.id}</th>
                        <td>{proc_elt.active ? <span style={{ color: "green" }}>ON</span> : <span style={{ color: "brown" }}>Off</span>}</td>
                        <td>{proc_elt.procName}</td>
                        <td>
                            <button className="btn" onClick={() => selectProc(proc_elt)}>
                                <img style={{ height: "35px", width: "auto" }} src="/images/pencil.svg" alt="Crayon" />
                            </button>
                            <button className="btn" onClick={() => deleteProc_onclick(proc_elt.procName, proc_elt.id)}>
                                <img style={{ height: "35px", width: "auto" }} src="/images/garbage-trash.svg" alt="Corbeille" />
                            </button>
                            {(confirmDelete && (proc_elt.id === proc_id)) && <button className="btn btn-danger fs-5" type="button" onClick={deleteProc}>Confirm Delete</button>}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    }

    if (!props.user.email) return <Header user={props.user} />

    return <div>

        <Header user={props.user} />

        <div className="body fs-4 m-3">

            <a className="my-3 fs-4 btn btn-lg btn-outline-secondary align-middle" href="/settings">
                {t("settings.back")}
            </a>

            <h1>{t("proc.header")}</h1>

            <button className={"btn btn-lg fs-4 btn-" + (proc_selected === null ? (formOpen ? "outline-info" : "info") : "danger")} onClick={() => toggleForm()}>
                {proc_selected === null ? t("proc.btn_add") : t("composante.btn_cancel_modify")}
            </button>

            {formOpen && <ProcesseurForm proc={proc_selected} refresh_list={refresh_list} />}

            {!formOpen && <>
                <RenderComposantes />
            </>}
        </div>
    </div>
}

export default ProcesseurAdmin;