import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MySpinner, slang } from "./Utils";
const proxyUrl = require("../../package.json").proxy;

export function MySelect({ myLabel, myName, refresh_itemToSave, idGroupe, data, func = null }) {

    // data : 0=id 1=text 2=defaultvalue

    const { t } = useTranslation();

    let default_value_filled = false;

    const getDefaultValue = function () {
        const find_result = data.find(obj => obj[2] === true)

        return (find_result) ? find_result[0] : "none";
    }

    return <div className="form-group my-3">
        <div className="input-group mb-3 border">
            <div className="input-group-prepend col-4">
                <label className="input-group-text fs-3 text-wrap text-start" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
            </div>
            <select
                id={"inputGroup" + idGroupe}
                className="custom-select fs-3 col-8"
                name={myName}
                defaultValue={getDefaultValue()}
                onChange={(e) => {
                    if (func) {

                        refresh_itemToSave(myName, func(e.target.value))

                    } else {

                        refresh_itemToSave(myName, e.target.value)
                    }
                }}
            >

                {data.map((elt, idx) => {
                    if (!default_value_filled) {
                        default_value_filled = elt[2];
                    }
                    return <option key={idx} value={elt[0]}>{elt[1]}</option>
                })}

                {!default_value_filled && <option value="none" disabled hidden>{t("general.select_default")}</option>}
            </select>
        </div>
    </div>
}

export function MySelectMultiple({ myLabel, myName, refresh_itemToSave, idGroupe, data, func = null }) {

    const { t } = useTranslation();

    function getSelectValues(select) {
        var result = [];
        var options = select && select.options;
        var opt;

        for (var i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                if (func) {

                    result.push(func(opt.value || opt.text));
                } else {

                    result.push(opt.value || opt.text);
                }
            }
        }
        return result;
    }

    return <div className="form-group mb-3">
        <div className="input-group mb-1">
            <label className="input-group-text fs-3 col-5" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>

            <select
                id={"inputGroup" + idGroupe}
                className="form-select fs-3 col-8"
                name={myName}
                multiple={true}
                onChange={(e) => {
                    refresh_itemToSave(myName, getSelectValues(e.target))
                }}
            >

                {data.map((elt, idx) => {

                    return <option key={idx} value={elt[0]}>{elt[1]}</option>
                })}

                <option value="none" disabled hidden>{t("general.select_default")}</option>
            </select>
        </div>
    </div>
}

export function MySelectSearch({ classInfo, myName, myDefaultValue = "none", refresh_itemToSave, idGroupe, myLabel, data, func = null, withExpand = true }) {

    const [expand, setExpand] = useState(true) // indique si le MySelectSearch est affiché
    const [text, setText] = useState("")

    const { t } = useTranslation();

    const [dataFilter, setDataFilter] = useState("");
    const [currentValue, setCurrentValue] = useState(myDefaultValue);

    const MIN_SEARCH_LENGTH = 2

    useEffect(() => {

        if ((myDefaultValue === "none") || (myDefaultValue === 0)) {
            setExpand(true)
        } else {
            setExpand(false)
            setCurrentValue(myDefaultValue)
            setText(myLabel + " " + myDefaultValue)
        }

    }, [myDefaultValue, myLabel])

    const compose_data = function () {
        // Permet de respecter le filtre actif

        if (dataFilter.length < MIN_SEARCH_LENGTH) return data

        return data.filter((d) => {
            return d[1].toLowerCase().includes(dataFilter.toLowerCase())
        })
    }

    if (withExpand) {

        if (!expand) return <p className={"my-3 me-3 " + classInfo} onClick={() => {
            setExpand(true)
        }} >{text}</p>
    }

    const makeExpandOff = function () {
        const elts_select = document.getElementById("inputGroup" + idGroupe).options

        const idx = elts_select.selectedIndex

        if (idx > -1) setText(elts_select[idx].text)

        setExpand(false)
    }

    return <div className="form-group  my-3">
        <div className="input-group mb-1">
            <label className="input-group-text fs-3" htmlFor={"inputGroup" + idGroupe} onClick={(e) => {
                if (!withExpand) return
                e.preventDefault()

                setExpand(false)
            }}>{myLabel}</label>

            <input
                type="text"
                className="form-control fs-3"
                style={{ maxWidth: "100px" }}
                value={dataFilter}
                placeholder={"search"}
                onChange={(e) => {
                    setDataFilter(e.target.value)
                }} />

            <button className="form-control fs-3" style={{ maxWidth: "50px" }} type="button" onClick={(e) => { setDataFilter("") }}>X</button>

            <select
                id={"inputGroup" + idGroupe}
                className="form-select fs-3"
                name={myName}
                value={currentValue}
                onChange={(e) => {

                    e.preventDefault()

                    if (e.target.value !== "none") makeExpandOff()

                    if (func) {

                        if (e.target.value === "none") {

                            setCurrentValue(0)
                            refresh_itemToSave(myName, func(0))
                        } else {

                            setCurrentValue(func(e.target.value))
                            refresh_itemToSave(myName, func(e.target.value))
                        }

                    } else {

                        setCurrentValue(e.target.value)
                        refresh_itemToSave(myName, e.target.value)
                    }
                }}
            >

                <option value="none">{t("general.select_default")}</option>

                {compose_data().map((elt, idx) => {

                    return <option key={idx} value={elt[0]}>{elt[1]}</option>
                })}

            </select>
        </div>

        {(dataFilter.length >= MIN_SEARCH_LENGTH) && <div className="btn-group mb-3 fs-3" role="group" aria-label="Buttons to select an item">

            {compose_data().map((elt, idx) => {
                return <button className="btn btn-lg fs-3 btn-outline-primary" key={idx} type="button" onClick={(e) => {

                    setDataFilter("")

                    document.getElementById("inputGroup" + idGroupe).value = elt[0]

                    makeExpandOff()

                    if (func) {

                        setCurrentValue(func(elt[0]))
                        refresh_itemToSave(myName, func(elt[0]))
                    } else {

                        setCurrentValue(elt[0])
                        refresh_itemToSave(myName, elt[0])
                    }

                }}>{elt[1]}</button>
            })}
        </div>}
    </div>
}

export function MyInput({ myLabel, myName, myValue, refresh_itemToSave, idGroupe, myType, step = 1, func = null, placeholder }) {

    return <div className="form-group my-3">
        <div className="input-group mb-3">
            <div className="input-group-prepend col-5">
                <label className="input-group-text fs-3 text-wrap text-start" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
            </div>
            <input
                id={"inputGroup" + idGroupe}
                className="form-control fs-3 col-7"
                name={myName}
                type={myType}
                step={step}
                defaultValue={myValue}
                placeholder={placeholder}
                onChange={(e) => {
                    if (func) {

                        refresh_itemToSave(myName, func(e.target.value))
                    } else {

                        refresh_itemToSave(myName, e.target.value)
                    }
                }}
            />
        </div>
    </div>
}

export function MyTextArea({ myLabel, myName, myValue, refresh_itemToSave, idGroupe, myType }) {

    return <div className="form-group my-3">
        <div className="input-group">
            <div className="input-group-prepend col-5">
                <label className="input-group-text fs-3  text-wrap text-start" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
            </div>
            <textarea
                id={"inputGroup" + idGroupe}
                className="form-control fs-3 col-8"
                name={myName}
                rows={4}
                defaultValue={myValue}
                onChange={(e) => {
                    refresh_itemToSave(myName, e.target.value)
                }}
            />
        </div>
    </div>
}

export function MyInputSearch({ myLabel, idGroupe, items, setItemsFiltered, filt1, filt2, filt3 }) {

    return <div className="input-group my-3">
        <label className="input-group-text fs-3" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
        <input
            id={"inputGroup" + idGroupe}
            type="text"
            className="form-control fs-3"
            style={{ maxWidth: "150px" }}

            placeholder={slang("recherche", "search")}
            onChange={(e) => {

                let filt = [];

                if (e.target.value.length === 0) {

                    filt = items
                } else {

                    filt = items.filter((t) => {

                        let filt_elt = ""

                        if (filt1) filt_elt += t[filt1]
                        if (filt2) filt_elt += t[filt2]
                        if (filt3) filt_elt += t[filt3]

                        return filt_elt.toLowerCase().includes(e.target.value.toLowerCase())
                    })
                }

                setItemsFiltered(filt)
            }} />

        <button
            className="form-control fs-3"
            style={{ maxWidth: "50px" }}
            type="button"
            onClick={() => { document.getElementById("inputGroup" + idGroupe).value = ""; setItemsFiltered(items) }}>X</button>
    </div>
}

export function MySwitch({ myLabel, myName, refresh_itemToSave, idGroupe }) {
    return <div className="form-check form-switch">

        <label className="form-check-label fs-3" htmlFor={"Switch" + idGroupe}>{myLabel}</label>

        <input className="form-check-input"
            type="checkbox"
            name="Switch"
            role="switch"
            id={"Switch" + idGroupe}
            onChange={(e) => {

                refresh_itemToSave(myName, e.target.checked)
            }}
        />

    </div>
}

export function MyCheckBox({ myLabel, myName, refresh_itemToSave, idGroupe }) {
    return <div className="form-check col my-2">
        <div className="row border-bottom">

            <div className="col-5">
                <label className="font-italic fs-3" htmlFor={"ON" + idGroupe}>{myLabel}</label>
            </div>

            <div className="col-4">

                <input className="form-check-input"
                    type="checkbox"
                    name="ON"
                    onChange={(e) => {
                        document.getElementById("OFF" + idGroupe).checked = false;
                        refresh_itemToSave(myName, true)
                    }}
                    id={"ON" + idGroupe} />
                <label className="form-check-label fs-5" htmlFor={"ON" + idGroupe}>
                    ON
                </label>
            </div>

            <div className="col-3">

                <input className="form-check-input"
                    type="checkbox"
                    name="OFF"
                    onChange={(e) => {
                        document.getElementById("ON" + idGroupe).checked = false;
                        refresh_itemToSave(myName, false)
                    }}
                    id={"OFF" + idGroupe} />
                <label className="form-check-label fs-5" htmlFor={"OFF" + idGroupe}>
                    OFF
                </label>
            </div>
        </div>
    </div>
}

export function MyImage({ myLabel, setImage, idGroupe }) {
    return <div className="input-group fs-3 border-bottom border-secondary my-3 py-3">
        <div className="input-group-prepend col-3">
            <label className="input-group-text fs-3" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
        </div>
        <input
            id={"inputGroup" + idGroupe}
            className="col-6 fs-3"
            type="file"
            accept=".jpg"
            onChange={(e) => setImage(e.target.files[0])}
        />

        <button className="btn btn-lg fs-3 btn-lg btn-outline-danger col-3 fs-3" type="button" onClick={(e) => { e.preventDefault(); setImage(null) }}>Reset Image</button>
    </div >
}

export function MyImagePreview({ image, base_url, id_image, bln_modify }) {

    // Gestion de l'URL de l'image
    const getImageURL = () => {
        if (!image && bln_modify) {

            return base_url.replace("id_image", id_image)
        }

        return URL.createObjectURL(image)
    }

    return <>
        {(image || bln_modify) &&
            <img className="my-3 rounded" style={{ maxHeight: "300px" }} src={getImageURL()} alt="élément / Item" />
        }
    </>
}

export const MySpinningSelectSearch = function ({ spinnerText, searchRefreshItemToSave, searchName, searchIdGroup, searchLabel, searchEndPoint }) {

    const [data, setData] = useState(null);

    // fetch Data
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(proxyUrl + '/' + searchEndPoint);
                const data = await response.json();
                setData(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [searchEndPoint])

    return <>

        {!data &&
            <MySpinner show={true} myText={spinnerText} />
        }

        {data &&
            <MySelectSearch
                myName={searchName}
                refresh_itemToSave={searchRefreshItemToSave}
                idGroupe={searchIdGroup}
                myLabel={searchLabel}
                data={data.map((d) => ([d.id, d.optionName]))}
                func={parseInt}
            />
        }
    </>
}