import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PostForm from "./PostForm";
import Header from "../Header";
import PaginatedItems from "../Paginator";
import { MyInputSearch } from "../FormElements";
import { NbItems } from "../Utils";
const proxyUrl = require("../../../package.json").proxy;

const PostAdmin = function (props) {

    const NB_CHARS_BY_POST = 50;

    const { t } = useTranslation();

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [posts, setPosts] = useState([]);
    const [postsFilt, setPostsFilt] = useState([]);
    const [post_selected, setPost_selected] = useState(null)

    const [post_id, setPost_id] = useState(0); // pour savoir quel élément supprimer

    const [formOpen, setFormOpen] = useState(false);

    const toggleForm = () => {
        if (formOpen) setPost_selected(null);
        setFormOpen(!formOpen);
    }

    const selectPost = (comp) => {

        setConfirmDelete(false)

        setPost_selected(comp);

        toggleForm();
    }

    useEffect(() => {

        const fetchPosts = async () => {
            try {
                const response = await fetch(proxyUrl + '/posts/');
                const data = await response.json();
                setPosts(data);
                setPostsFilt(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchPosts();

    }, [])


    const deletePost = () => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/post', {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({ id: post_id })
                });

                if (response.status === 201) window.location.reload();

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmDelete(false)
    }

    const deletePost_onclick = (id) => {
        setPost_id(id)
        setConfirmDelete(!confirmDelete)
    }

    const RenderPosts = () => {

        const [currentItems, setCurrentItems] = useState([]);

        const table_titles = [
            "id",
            t("post.title"),
            t("post.content"),
            t("post.language"),
        ]

        return <>
            <PaginatedItems itemsPerPage={15} items={postsFilt} setCurrentItems={setCurrentItems} />

            <table className="table table-hover">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                <tr>
                        <td colSpan={table_titles.length} >

                            <NbItems count={currentItems.length} total={postsFilt.length} />
                        </td>
                    </tr>
                    {postsFilt.map((post_elt) => (
                        <tr key={post_elt.id}>
                            <th scope="row">{post_elt.id}</th>
                            <td>{post_elt.title}</td>
                            <td>{post_elt.content.substring(0, NB_CHARS_BY_POST)}</td>
                            <td>{post_elt.language}</td>
                            <td>
                                <button className="btn" onClick={() => selectPost(post_elt)}>
                                    <img style={{ height: "35px", width: "auto" }} src="/images/pencil.svg" alt="Crayon" />
                                </button>
                                <button className="btn" onClick={() => deletePost_onclick(post_elt.id)}>
                                    <img style={{ height: "35px", width: "auto" }} src="/images/garbage-trash.svg" alt="Corbeille" />
                                </button>
                                {(confirmDelete && (post_elt.id === post_id)) && <button className="btn btn-danger" type="button" onClick={deletePost}>Confirm Delete</button>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    return <div className="fs-4">

        <Header user={props.user} />

        <div className="body">

            <a className="my-3 fs-3 btn btn-lg btn-outline-secondary align-middle" href="/settings">
                {t("settings.back")}
            </a>

            <h1>{t("post.header")}</h1>

            <button className={"btn btn-lg fs-3 mb-3 btn-" + (post_selected === null ? (formOpen ? "outline-info" : "info") : "danger")} onClick={() => toggleForm()}>
                {post_selected === null ? t("post.btn_add") : t("ordi.btn_cancel_modify")}
            </button>

            {formOpen && <PostForm post={post_selected} />}

            <MyInputSearch
                myLabel="Rechercher un Post"
                idGroupe={100}
                items={posts}
                setItemsFiltered={setPostsFilt}
                filt1="id"
                filt2="title"
            />

            <RenderPosts />
        </div>

    </div>
}

export default PostAdmin;