import { useState, useEffect } from 'react';

import MenuProductItem from './MenuProductItem';

import MenuFamilies from './MenuFamilies';

import digiboboimg from "../images/logo_digibobo3.png";
import placeholderimg from "../images/placeholder2.png";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MySpinner, rangDossiers, slang } from '../Utils';
import MenuGroups from './MenuGroups';
import MyFullScreenDiv from './MyFullScreenDiv';
const proxyUrl = require("../../../package.json").proxy;

export default function MenuProducts(props) {

    const { t } = useTranslation();

    const { idData } = useParams();

    const logoImage = proxyUrl + "/uploads/" + idData + "/0-" + rangDossiers.menu_logo + ".jpg"
    const photoImage = proxyUrl + "/uploads/" + idData + "/0-" + rangDossiers.menu_photo + ".jpg"

    const [showFullPic,
        setShowFullPic] = useState(false);
    const [fullImgLabel,
        setFullImgLabel] = useState("");
    const [fullImgSrc,
        setFullImgSrc] = useState("");

    const [currentIdFamille,
        setCurrentIdFamille] = useState(-2); // -2 d'abord pour qu'on sache qu'on vient à peine d'arriver    

    const [currentIdFamilleGroupe,
        setCurrentIdFamilleGroupe] = useState(-2); // -2 d'abord pour qu'on sache qu'on vient à peine d'arriver

    const [searchProduct,
        setSearchProduct] = useState("");

    const [pdts,
        setPdts] = useState([]);

    const [boolBtnStyle,
        setBoolBtnStyle] = useState(true);

    const [currentData,
        setCurrentData] = useState({});

    const [showSpinner, setShowSpinner] = useState(false)
    const [showSpinner2, setShowSpinner2] = useState(false)

    const FAMILLE_SERVICE_URL = proxyUrl + "/familles_menu/" + idData;

    const [familles, setFamilles] = useState([])

    // FETCH the families list
    useEffect(() => {

        const fetchFamilies = async () => {

            setShowSpinner2(true)

            fetch(FAMILLE_SERVICE_URL)
                .then(response => response.json())
                .then(result => {
                    return result.sort(function (a, b) { // Fonction qui permet de trier les familles par ordre alphabétique
                        return a.NomFamille.localeCompare(b.NomFamille);
                    });
                })
                .then(result => {
                    setFamilles(result);
                })
                .catch(e => {
                    console.log(e);
                });

            setShowSpinner2(false)
        };
        fetchFamilies();
    }, [idData, FAMILLE_SERVICE_URL]);

    // Fetch Data_menu
    useEffect(() => {

        (async () => {
            try {
                if (idData === 0) return

                const response = await fetch(proxyUrl + '/data_menu/' + idData);

                const data = await response.json();

                setCurrentData(data[0]);

            } catch (error) {
                console.error(error);
            }
        })();
    }, [idData])

    // Fetch Pdts
    useEffect(() => {

        (async () => {

            setShowSpinner(true)

            try {

                if (idData === 0) return

                const response = await fetch(proxyUrl + '/produits/' + idData);

                const data = await response.json();

                setPdts(data);

            } catch (error) {
                console.error(error);
            }

            setShowSpinner(false)

        })();
    }, [idData])

    const products_family = pdts.reduce(function (filtered, product) {

        if (searchProduct.length > 0) {
            if (product.NomProduit.toLowerCase().includes(searchProduct.toLowerCase())) {

                filtered.push(product);
            }
        } else {

            switch (currentIdFamille) {

                case -2: // NONE
                    return [];

                case -1: // ALL
                    filtered.push(product);
                    break;

                default:
                    if (product.IdFamille === currentIdFamille) {

                        filtered.push(product);
                    }
                    break;
            }
        }
        return filtered;
    }, []);

    return <div>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>

            <img className="rounded" style={{ maxHeight: "200px", width: "auto" }} src={logoImage} onError={(e) => { e.target.onerror = null; e.target.src = digiboboimg }} alt="Logo Entreprise" />

        </div>

        <div className="jumbotron jumbotron-fluid bg-body-tertiary my-3">
            <div className="container">
                <h1 className="display-2 text-center">{currentData.menuNomEntreprise}</h1>
                <p className="lead fs-4 text-center pb-3">{currentData.menuDescription}</p>
            </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>

            <img className="rounded" style={{ maxHeight: "400px", width: "auto" }} src={photoImage} onError={(e) => { e.target.onerror = null; e.target.src = placeholderimg }} alt="Site" />

        </div>

        <h1 style={{ textAlign: "center", margin: "50px" }}>MENU</h1>


        <>
            {(searchProduct.length === 0) &&
                <>
                    <MenuGroups
                        infoIdFamilleGroupe={currentIdFamilleGroupe}
                        changeIdFamilleGroupe={setCurrentIdFamilleGroupe}
                        changeIdFamille={setCurrentIdFamille}
                        idData={idData}
                    />

                    <MySpinner show={showSpinner2} myText={t("general.loading") + slang(" Familles", " Families")} />

                    {currentIdFamilleGroupe > -2 && <MenuFamilies
                        infoIdFamilleGroupe={currentIdFamilleGroupe}
                        infoIdFamille={currentIdFamille}
                        familles={familles}
                        changeIdFamille={(idFamille) => {
                            setCurrentIdFamille(idFamille)
                            setBoolBtnStyle(!boolBtnStyle)
                            setSearchProduct("")
                        }}
                        idData={idData}
                    />}
                </>
            }

            <div style={{ marginBottom: "70px" }}>

                {/* SEARCH PRODUCT */}


                <div className="my-2 p-2">

                    <div className="col-6 mx-auto">

                        <p className="text-center fs-3 m-1 mb-3 pb-3 text-primary">{t("menu.nb_products") + " : " + Object.keys(products_family).length}</p>

                        <div className="col-10 mx-auto">

                            <input className="col-8 fs-4 p-1 m-1 border border-secondary" value={searchProduct} placeholder={t("menu.search_product")} onChange={(e) => {
                                setSearchProduct(e.target.value)
                            }} />

                            <button className="col-3 btn btn-lg btn-outline-danger fs-4"
                                onClick={() => setSearchProduct("")}
                                style={{ margin: "5px", padding: "5px" }}
                            >
                                {t("menu.btn_clear")}
                            </button>

                        </div>
                    </div>
                </div>

                {/* LISTE PRODUCT */}

                <MySpinner show={showSpinner} myText={t("general.loading") + " Pdts"} />

                <ul>
                    {products_family.map((produit, index) => (
                        <MenuProductItem
                            key={produit.IdProduit.toString() + produit.IdFraction.toString()}
                            search={false}
                            idData={idData}
                            produit={produit}
                            setShowFullPic={setShowFullPic}
                            setFullImgLabel={setFullImgLabel}
                            setFullImgSrc={setFullImgSrc}
                        />
                    ))}
                </ul>
            </div >


        </>

        <div className="scrollButton mb-3 pb-3">

            {/* IMAGE EN PLEIN ECRAN */}

            {(showFullPic) &&
                <MyFullScreenDiv
                    setShowFullPic={setShowFullPic}
                    fullImgLabel={fullImgLabel}
                    fullImgSrc={fullImgSrc}
                />
            }
            {(!showFullPic) && <>
                <a className={"me-2 p-3 btn btn-lg text-primary btn" + (boolBtnStyle ? "-warning" : "-outline-primary bg-light") + " border border-3 border-primary fs-4"} href={"https://wa.me/" + currentData.menuWhatsapp + "/?text=menu_online"} id="redirect_to_whatsapp">{t("settings.menuChat") + currentData.menuNomEntreprise}</a>

                <svg onClick={() => window.scrollTo(0, 0)} xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="tomato" className="bi bi-arrow-up-square" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                </svg>
            </>
            }
        </div>

    </div >
}