
import { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import { useParams } from "react-router-dom";
import { format_date } from "../Utils";
import BackButton from '../BackButton';

const proxyUrl = require("../../../package.json").proxy;

const OrderPage = function (props) {

    const [order, setOrder] = useState({
        idOrder: 0,
        phone: "678532492",
        total: 100000,
        dateHeure: Date.now(),
        treated: false,
        content: { title: "this is a fake order", other: "je sens la puissance monter" }
    });

    const [confirmTreated, setConfirmTreated] = useState(false);

    const { current_idOrder } = useParams();

    useEffect(() => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + "/order/" + current_idOrder)

                if (response.status === 201) {

                    const data = await response.json()
                    setOrder({...data, content: JSON.parse(data.content)});
                }
            } catch (error) {
                console.error(error);
            }
        })();

    }, [current_idOrder]);

    const mardOrderAsTreated = async () => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + "/order_treated/" + current_idOrder)

                if (response.status === 201) window.location.reload()

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmTreated(false)
    }
    

    return <div>
        <Header user={props.user} />

        <BackButton />

        <div className="body">
            <h1>Commande {order.idOrder}</h1>

            <p>Date : {format_date(order.dateHeure)}</p>

            <p>Phone : {order.phone}</p>
            <p>Total : {order.total}</p>

            <p>Treated : {order.treated ? "True" : "False"}

                {!order.treated &&
                    <>
                        <button className="btn btn-outline-primary mx-3" onClick={() => setConfirmTreated(!confirmTreated)}>
                            <img style={{ height: "30px", width: "auto" }} src="/images/select.svg" alt="Corbeille" />
                            Mark as treated
                        </button>
                        {(confirmTreated) && <button className="btn btn-danger" type="button" onClick={mardOrderAsTreated}>Confirm Treated</button>}
                    </>
                }
            </p>

            <pre>
                <code>
                    {JSON.stringify(order.content, null, '  ')}

                </code>
            </pre>

        </div>

        <Footer />
    </div>
}

export default OrderPage;