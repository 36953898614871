import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PaginatedItems from "../Paginator";
import { displaySnackbar, format_date_nohour as format_date_no_hour, liste_priv, NbItems, slang, userHasPriv } from "../Utils";
import { MyInputSearch } from "../FormElements";
const proxyUrl = require("../../../package.json").proxy;

// Liste des Utilisateurs avec les dates de Fin d'accès

export default function ListeUsersAccesCloud({ current_user, users, refreshUser, setRefreshUser }) {

    const { t } = useTranslation()

    const [currentItems, setCurrentItems] = useState([]);

    const [selected_user, setSelected_user] = useState(0);

    const [users_filtered, setUsers_Filtered] = useState([]);

    const [dateFinAccess, setDateFinAccess] = useState(0);

    const [confirmValidation, setConfirmValidation] = useState(false);

    const [confirmPasswordReset, setConfirmPasswordReset] = useState(false);

    useEffect(() => {

        setUsers_Filtered(users)
    }, [users]);

    // click on Save Date Button
    const SaveDate = (id) => {

        (async () => {

            try {

                const response = await fetch(proxyUrl + "/user_date", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({ id: id, date: dateFinAccess }),
                })

                if (response.status === 201) {

                    displaySnackbar("User " + id + " : " + slang("Date enregistrée !", "Date saved !"), slang("Succès", "Success"), "warning")

                    setRefreshUser(!refreshUser)
                    setSelected_user(0)
                }

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmValidation(false)
    }

    // click on Validation Button
    const ValidateElement = (id) => {

        (async () => {

            try {

                const response = await fetch(proxyUrl + "/user_validation", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({ id: id }),
                })

                if (response.status === 201) {

                    displaySnackbar("User " + id + " : " + slang("Date validée !", "Date validated !"), slang("Succès", "Success"), "success")

                    setRefreshUser(!refreshUser)
                    setSelected_user(0)
                    setConfirmValidation(false)
                }

            } catch (error) {
                displaySnackbar("User " + id + " : " +error, slang("Erreur", "Error"), "danger")
            }
        })();

    }

    const validateElement_onclick = (id) => {

        if (confirmValidation) {

            setSelected_user(0)

            // setSelected_user((selected_user === id) ? 0 : id)

        } else {

            if (selected_user === 0) setSelected_user(id)

        }

        setConfirmValidation(!confirmValidation)
        setConfirmPasswordReset(false)
    }

    // click on Reset Password
    const ResetPassword = (id) => {

        (async () => {

            try {

                const response = await fetch(proxyUrl + "/user_pwd_reset/" + id, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                })

                if (response.status === 200) {

                    displaySnackbar("User " + id + " : " + slang("Mot de passe réinitialisé à 0000 !", "Password resetted to 0000 !"), slang("Succès", "Success"), "success")

                    setRefreshUser(!refreshUser)
                    setSelected_user(0)
                    setConfirmPasswordReset(false)
                }

            } catch (error) {
                
                displaySnackbar("User " + id + " : " +error, slang("Erreur", "Error"), "danger")
            }
        })();

    }



    const ResetPassword_onclick = (id) => {

        if (confirmPasswordReset) {

            setSelected_user(0)

            // setSelected_user((selected_user === id) ? 0 : id)

        } else {

            if (selected_user === 0) setSelected_user(id)

        }

        setConfirmPasswordReset(!confirmPasswordReset)
        setConfirmValidation(false)
    }

    const selectLic = async (id) => {


        if (!confirmValidation && !confirmPasswordReset){

            setSelected_user((selected_user === id) ? 0 : id)
        }

        setConfirmPasswordReset(false)
        setConfirmValidation(false)
    }

    const ListeOptions = function ({ user }) {

        const id = user.id

        let show_pencil = false

        if (userHasPriv(user, liste_priv.digibobo.admin)) {

            // Seul un admin peut modifier la date d'un admin
            // et également réinitialiser son mot de passe

            show_pencil = userHasPriv(current_user, liste_priv.digibobo.admin)
        } else {

            show_pencil = true
        }

        return <td>
            {((id === selected_user) && ((!confirmValidation) && (!confirmPasswordReset))) && <form onSubmit={e => e.preventDefault()}>
                <input type="date" className="form-input" onChange={(e) => { setDateFinAccess(e.target.value) }} value={dateFinAccess} />

                <button type="submit" className="btn btn-lg m-1 btn-warning" onClick={(e) => SaveDate(id)}>Save</button>
            </form>}

            {show_pencil && <>

                <button className="btn" onClick={() => selectLic(id)}>
                    <img style={{ height: "40px", width: "auto" }} src="/images/pencil.svg" alt="Pencil" />
                </button>

                <button className="btn" onClick={() => ResetPassword_onclick(id)}>
                    <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        width="40" viewBox="0 0 47 47" xml="preserve" >
                        <g>
                            <g>
                                <path fill="#00FF00" d="M37.076,19.748c-0.824,0-1.494,0.672-1.494,1.499v1.46h2.991v-1.46C38.573,20.42,37.9,19.748,37.076,19.748z" />
                                <path fill="gray" d="M41.227,12.635H5.773C2.6,12.635,0,15.237,0,18.41v10.18c0,3.178,2.6,5.775,5.773,5.775h35.454
			C44.4,34.365,47,31.768,47,28.59V18.41C47,15.237,44.4,12.635,41.227,12.635z M12.56,25.077l1.385,1.508l-2.399,1.8l-0.984-1.8
			c-0.145-0.257-0.372-0.761-0.691-1.508c-0.349,0.821-0.581,1.321-0.696,1.508l-0.998,1.8l-2.466-1.8l1.491-1.508
			c0.42-0.415,0.827-0.771,1.229-1.063c-0.41-0.037-0.949-0.113-1.614-0.238l-2.065-0.336l0.958-2.812l1.852,0.842
			c0.195,0.088,0.68,0.365,1.452,0.824c-0.165-0.747-0.271-1.302-0.319-1.666l-0.263-2.013h2.941l-0.238,2.013
			c-0.052,0.49-0.166,1.045-0.335,1.666c0.339-0.17,0.563-0.283,0.669-0.345c0.372-0.207,0.659-0.35,0.852-0.442l1.853-0.879
			l0.918,2.812l-2.049,0.428c-0.375,0.072-0.931,0.119-1.67,0.146C11.874,24.406,12.266,24.758,12.56,25.077z M26.137,25.077
			l1.387,1.508l-2.399,1.799l-0.983-1.799c-0.145-0.257-0.372-0.761-0.69-1.508c-0.35,0.821-0.58,1.321-0.695,1.508l-1,1.799
			l-2.465-1.799l1.491-1.508c0.42-0.415,0.826-0.771,1.229-1.063c-0.408-0.037-0.949-0.113-1.614-0.238l-2.066-0.336l0.959-2.812
			l1.853,0.842c0.193,0.088,0.68,0.365,1.451,0.824c-0.169-0.747-0.275-1.302-0.32-1.666l-0.264-2.011h2.942l-0.239,2.011
			c-0.054,0.49-0.166,1.045-0.333,1.666c0.337-0.17,0.561-0.283,0.668-0.345c0.37-0.207,0.658-0.35,0.852-0.442l1.854-0.879
			l0.919,2.812l-2.051,0.428c-0.375,0.072-0.929,0.119-1.669,0.146C25.451,24.406,25.844,24.758,26.137,25.077z M41.35,27.625
			c0,0.619-0.507,1.125-1.125,1.125H33.93c-0.619,0-1.125-0.506-1.125-1.125v-3.794c0-0.619,0.506-1.125,1.125-1.125h0.152v-1.46
			c0-1.653,1.343-2.998,2.994-2.998c1.652,0,2.996,1.344,2.996,2.998v1.46h0.152c0.617,0,1.125,0.506,1.125,1.125V27.625z"/>
                            </g>
                        </g>
                    </svg>
                </button>

            </>
            }

                {(userHasPriv(current_user, liste_priv.digibobo.admin) && (!user.validation)) &&
                    <button className="btn" onClick={() => validateElement_onclick(id)}>
                        <img style={{ height: "40px", width: "auto" }} src="/images/validation.svg" alt="Validation" />
                    </button>
                }

                {(confirmPasswordReset && (id === selected_user)) &&
                    <button className="btn btn-danger fs-4" type="button" onClick={(e) => { ResetPassword(id) }}>Reset Pwd to 0000</button>
                }

                {(confirmValidation && (id === selected_user)) &&
                    <button className="btn btn-warning fs-4" type="button" onClick={(e) => { ValidateElement(id) }}>{t("general.confirm")} Validation</button>
                }
        </td>
    }

    const table_titles = [
        "id",
        "Mail",
        "Username",
        "Phone",
        "Date",
        "Validation",
        "Options"
    ]

    return <>
        <PaginatedItems itemsPerPage={5} items={users_filtered} setCurrentItems={setCurrentItems} />

        < div className="col-12 col-md-8 input-group mb-1 fs-6">
            <MyInputSearch
                myLabel={t("User")}
                idGroupe={15}
                items={users}
                setItemsFiltered={setUsers_Filtered}
                filt1="id"
                filt2="username"
                filt3="email"
            />
        </div>
        <table className="table table-hover">
            <thead>
                <tr>
                    {table_titles.map((title_elt, i) => (
                        <th key={i} scope="col">{title_elt}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={table_titles.length} >

                        <NbItems count={currentItems.length} total={users_filtered.length} />
                    </td>
                </tr>
                {currentItems.map((user_elt) => (
                    <tr key={user_elt.id}>
                        <th scope="row">{user_elt.id}</th>
                        <td>{user_elt.email}</td>
                        <td>{user_elt.username}</td>
                        <td>{user_elt.phone}</td>
                        <td>{format_date_no_hour(user_elt.dateFin)}</td>
                        <td>{user_elt.validation ? "True" : "False"}</td>

                        <ListeOptions user={user_elt} />
                    </tr>
                ))}
            </tbody>
        </table>
    </>
}