
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function BackButton() {

  const {t} = useTranslation();

  let navigate  = useNavigate();

  const goBack = () => {
		navigate(-1);
	}

  return (
    <div className="d-inline" style={{width:"150px"}}>

    <button className="btn btn-secondary fs-3" onClick={() => { goBack() }}>{t("general.back")}</button>
    </div>
  );
}
