
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const levels = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10
];

function RadioButtonsGroup(props) {

    const [value,
        setValue] = useState(0);

    const handleChange = (event) => {
        setValue(parseInt(event.target.value));
    };

    props.get_pack_selected_items([props.level, value]); // updates each time RadioButtonGroup's value is changed and so when it's rerendered !

    return (

        <div>
            <p className="fs-4 mt-3 mb-0" style={{ "color": "blue" }}>{props.productName + " - Level " + props.level}</p>

            {props.elements.map((elt, index) => {
                const radioLabel = <span>&nbsp; {"( x" + elt.Qte + " ) " + elt.nomProduit.toUpperCase()}</span>;
                const radioId = "flexRadioDefault" + props.level + index;
                return (

                    <div className="fs-3" key={index} onChange={e => handleChange(e)}>
                        <input className="" type="radio" name={"flexRadioDefault" + props.level} id={radioId} value={index} defaultChecked={value === index} />
                        <label className="" htmlFor={radioId}>
                            {radioLabel}
                        </label>
                    </div>
                );
            })}
        </div>

    );
}


function ProductPack({ pack_level_elements, get_pack_selected_items, productName }) {

    return (
        <div style={{ paddingLeft: "10px", background: "oldlace" }}>
            {levels
                .filter(level => {
                    return pack_level_elements[level].length > 0;
                })
                .map((level, index) => {

                    const elements = pack_level_elements[level];

                    return (
                        <div key={index}>
                            <RadioButtonsGroup elements={elements} level={level} get_pack_selected_items={get_pack_selected_items} productName={productName} />
                        </div>
                    );
                })}
        </div>
    );
}

export default function ProductItemPack({ idModal, packOpen, setPackOpen, productName, sendCommandToNewRound, pack_level_elements, get_pack_selected_items }) {

    const {t}=useTranslation()

    const handleClose = () => {
        setPackOpen(false)
    };

    if (!packOpen) return <></>

    return (

        <div id={idModal}>

            <div>
                <ProductPack pack_level_elements={pack_level_elements} get_pack_selected_items={get_pack_selected_items} productName={productName} />
            </div>

            <button className="btn btn-lg btn-outline-danger m-2 mb-3" onClick={handleClose}>
                {t("general.close")}
            </button>

            <button className="btn btn-lg btn-primary m-2 mb-3" onClick={() => {
                sendCommandToNewRound();
                handleClose();
            }} variant="contained" color="primary">
                {t("general.add")}
            </button>
        </div>
    );
}