import { useState, useEffect } from "react"
import { MyCheckBox, MyInput, MyInputSearch, MySelectMultiple, MySelectSearch } from "../FormElements";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { MyScrollButton, MySpinner, NbItems, digitGroup, displaySnackbar, jump, slang } from "../Utils";
import PaginatedItems from "../Paginator";
const proxyUrl = require("../../../package.json").proxy;

const SettingsPdts = function ({ user }) {

    const { t } = useTranslation()

    const [doss_clts, setDoss_clts] = useState([])
    const [current_idDossClt, setCurrent_idDossClt] = useState(0)

    const [stock, setStock] = useState([])
    const [stock_filtered, setStock_filtered] = useState([])

    const [familles, setFamilles] = useState([])

    const [pdtsWithBoolImageExists, setPdtsWithBoolImageExists] = useState([])

    const [pdts, setPdts] = useState([])
    const [pdts_selected, setPdts_selected] = useState([])
    const [pdts_filtered, setPdts_filtered] = useState([])

    const [datas, setDatas] = useState([])
    const [current_idData, setCurrent_idData] = useState(0)

    const [magasins, setMagasins] = useState([])
    const [current_idMagasin, setCurrent_idMagasin] = useState(0)

    const [newAppro, setNewAppro] = useState([])
    const [refreshAppro, setRefreshAppro] = useState(false)

    const [confirmMergeOpen, setConfirmMergeOpen] = useState(false)

    const [choixOnglet, setChoixOnglet] = useState(0) // 0 = aucun, 1 = liste, 2 = stock, 3 = nouveau pdt

    const [pdtOpenToModif, setPdtOpenToModif] = useState()

    const [showSpinner1, setShowSpinner1] = useState(false)
    const [showSpinner2, setShowSpinner2] = useState(false)
    const [showSpinner3, setShowSpinner3] = useState(false)
    const [showSpinner4, setShowSpinner4] = useState(false)

    const [delete_approItem, setDelete_approItem] = useState(0); // pour savoir quelle ligne d'appro supprimer
    const [confirmDelete, setConfirmDelete] = useState(false);

    const typesSelectData = {
        all: 0,
        none: 1,
        selection: 2
    }

    const listeOnglets = {
        pdts_liste: 1,
        pdts_stocks: 2,
        pdts_images: 3,
        pdts_appro: 4,
        pdts_nouveau: 5,
    }

    const filterPdtsAndStock = function (idData_value, choixOnglet_value) {

        if (idData_value === 0) {

            setChoixOnglet(0)

            if ((choixOnglet_value === listeOnglets.pdts_liste) || (choixOnglet_value === listeOnglets.pdts_appro))
                setPdts_filtered(pdts)

            if (choixOnglet_value === listeOnglets.pdts_stocks)
                setStock_filtered(stock)
        } else {

            if ((choixOnglet_value === listeOnglets.pdts_liste) || (choixOnglet_value === listeOnglets.pdts_appro)) {

                const pdts_filt = pdts.filter(p => (p.idDataSource === idData_value))

                setPdts_filtered(pdts_filt)
            }

            if (choixOnglet_value === listeOnglets.pdts_stocks) {

                const stock_filt = stock.filter(s => (s.idDataSource === idData_value))

                setStock_filtered(stock_filt)
            }
        }
    }

    const handleChoice = function (choice) {
        if (choixOnglet === choice) {

            setChoixOnglet(0)
        } else {

            setChoixOnglet(choice)
            filterPdtsAndStock(current_idData, choice)
        }
    };

    // Fetch DossierClient
    useEffect(() => {

        (async () => {

            const lecture_priv_doss_clt = function () {

                // donne tous les idDossierClients pour lesquels liste_priv.dossier.produit est présent

                const liste_doss = []

                if (!user.privileges) return liste_doss

                user.privileges.forEach(p => {

                    if ((p.idDossierClient > 0) && (p.idData === 0)) {

                        liste_doss.push(p.idDossierClient)
                    }
                })

                return liste_doss

            }

            let crit_doss_clt = lecture_priv_doss_clt()

            if (crit_doss_clt.length === 0) crit_doss_clt = [0]

            try {
                const response = await fetch(proxyUrl + '/doss_clts_criteria', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        crit_doss_clts: " id IN (" + crit_doss_clt.join(', ') + ")"
                    })
                });

                const data = await response.json();

                if (data.length === 1) {
                    setCurrent_idDossClt(data[0].id)
                }

                setDoss_clts(data);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [user.privileges])

    // Fetch Pdts
    useEffect(() => {

        (async () => {

            if (current_idDossClt === 0) {
                setPdts([]);
                setPdts_filtered([]);
                return
            }

            try {

                setShowSpinner1(true)

                const response = await fetch(proxyUrl + '/pdts/' + current_idDossClt);

                setShowSpinner1(false)

                const data = await response.json();
                setPdts(data);
                setPdts_filtered(data);

            } catch (error) {
                console.error(error);
            }


        })();
    }, [current_idDossClt])

    // Fetch Pdts with boolean indicating image
    useEffect(() => {


        (async () => {

            if (current_idData === 0) {
                setPdtsWithBoolImageExists([])
                return
            }

            try {

                setShowSpinner2(true)

                const response = await fetch(proxyUrl + '/pdtsImageExists/' + current_idData);

                setShowSpinner2(false)

                const data = await response.json();

                setPdtsWithBoolImageExists(data);

            } catch (error) {
                console.error(error);
            }


        })();
    }, [current_idData])

    // Fetch Familles
    useEffect(() => {

        (async () => {

            try {

                if (current_idData === 0) return

                setShowSpinner1(true)

                const response = await fetch(proxyUrl + '/familles/' + current_idData);

                setShowSpinner1(false)

                const data = await response.json();
                setFamilles(data);

            } catch (error) {
                console.error(error);
            }


        })();
    }, [current_idData])

    // Fetch Stocks Actuels
    useEffect(() => {

        (async () => {

            if (current_idDossClt === 0) {
                setStock([])
                setStock_filtered([])
                return
            }

            try {

                setShowSpinner3(true)

                const response = await fetch(proxyUrl + '/stock_actuel/' + current_idDossClt);

                setShowSpinner3(false)

                const data = await response.json();
                setStock(data);
                setStock_filtered(data);


            } catch (error) {
                console.error(error);
            }
        })();
    }, [current_idDossClt])

    // Fetch Datas
    useEffect(() => {

        if (current_idDossClt === 0) {
            setDatas([])
            setCurrent_idData(0)
            return
        }

        const fetchData = async () => {
            try {

                const response = await fetch(proxyUrl + '/datas/' + current_idDossClt)

                const data = await response.json();

                setDatas(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [current_idDossClt])

    // Fetch Magasins
    useEffect(() => {

        if (current_idData === 0) {
            setMagasins([])
            return
        }

        const fetchData = async () => {
            try {

                const response = await fetch(proxyUrl + '/magasins/' + current_idData)

                const data = await response.json();

                setMagasins(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [current_idData])


    // Fonctions supports...

    // ...générale

    let i = 0;

    const getIdGroupe = () => {
        i++
        return i
    }

    const getNomProduit = function (pdt) {

        return pdt.NomProduit + " (" + pdt.IdProduit + "-" + pdt.idDataSource + ")"
    }
    // ...du Merge, Price Modification, New Product
    const create_price_pending = async function ({ typeElement, idData, idLigne, bigCode, price }) {

        const body = {
            idData: idData,
            typeElement: typeElement,
            idLigne: idLigne,
            bigCode: bigCode,
            price: price,
            idUser: user.id,
        }

        return await fetch(proxyUrl + "/pending", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",

            // Pending : 1. Reset pwd  2. Remise accordée  3. Suppr tk accordée  4. Transfert validé
            //   5. Transfert en attente de validation  6. Transfert refusé  7. maj PV1 Pdt  8. maj PV2 Pdt  9. maj PV3 Pdt  10. maj PA Pdt  11. maj bigCode Pdt

            body: JSON.stringify(body),
        })
    }

    const create_new_pdt = async function ({ idData, nomProduit, barcode, IdFamille, stockable, estFluide, bigCode, pv1, pv2, pv3, pa }) {

        const body = {
            idData: idData,
            typeElement: 12,
            idLigne: 0,
            bigCode: bigCode,
            idUser: user.id,
            content: {
                nomProduit: nomProduit,
                barcode: barcode,
                stockable: stockable,
                estFluide: estFluide,
                pv1: pv1,
                pv2: pv2,
                pv3: pv3,
                pa: pa,
                IdFamille: IdFamille,
            }
        }

        return await fetch(proxyUrl + "/pending", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",

            body: JSON.stringify(body),
        })
    }

    // Select One Product
    const selectOnePdt = function (pdt) {
        const pdts_curr = pdts_selected.slice();

        pdts_curr.push(pdt)

        setPdts_selected(pdts_curr)

        setConfirmMergeOpen(false)

        displaySnackbar(getNomProduit(pdt) + slang(" ajouté à la liste", " added to the merge list"), slang("Succès", "Success"), "info")
    }

    // Fusionne les produits sélectionnés (envoi le même bigCode)
    const mergeProducts = async function () {

        // promise all fetch --> pour création de pendings

        let bigCodeRef = 0 // bigCode qu'il va falloir donner à tous les produits de la liste
        let idxRef = 0 // index du produit dont le bigCode va être donné à tous les autres
        let shouldSkip = false;
        let message = slang("Rapport de Fusion", "Merge report") + " :\n";

        const pdts_to_merge = [...new Set(pdts_selected)] // j'enlève les doublons

        pdts_to_merge.forEach((p, idx) => {

            if (shouldSkip) return

            if (p.bigCode) { // on retient le 1er bigCode qui apparaît et son index

                bigCodeRef = pdts_to_merge[0].bigCode
                idxRef = idx
                shouldSkip = true;
                return
            }
        })

        await Promise.all(pdts_to_merge.map(async (p, idx) => {

            if (idx === idxRef) return // pour qu'on ne perde pas le temps à redonner le même bigCode

            const response = await create_price_pending({
                typeElement: 11,
                idData: p.idData,
                idLigne: p.IdProduit,
                bigCode: bigCodeRef,
                price: 0,
            })

            message = message + getNomProduit(p) + " : " + ((response.status === 201) ? "true" : "false") + "\n"

        }));

        displaySnackbar(message, "Success", "success")

        setPdts_selected([])
    }


    // Formulaire de modification des prix d'un Produit
    const ModifPdtPricesForm = function ({ pdt }) {

        const basicPending = {
            idUser: user.id,
            pv1: undefined,
            pv2: undefined,
            pv3: undefined,
            pa: undefined,
        }

        const [newPending, setNewPending] = useState(basicPending)

        const [selectData, setSelectData] = useState(typesSelectData.all)

        // Product's Price Modification
        const modifyProduct = async function (e) {


            let message = getNomProduit(pdt) + "\n"

            e.preventDefault()

            // on tient compte du réglage All data le cas échéant

            setShowSpinner4(true)

            if ((selectData === typesSelectData.all) || (selectData === 0)) {

                newPending.idData = datas.map((data) => (data.id))

            } else if ((selectData === typesSelectData.none) || (newPending.idData.length === 0)) { // on se rassure qu'il y a au moins 1 data où créer le produit

                displaySnackbar(slang("Sélectionnez au moins une base de données", "Please select at least one database"), "Warning", "warning")
                setShowSpinner4(false)
                return
            }

            const prices = [
                [newPending.pv1, 7],
                [newPending.pv2, 8],
                [newPending.pv3, 9],
                [newPending.pa, 10]
            ]

            await Promise.all(prices.map(async (p) => {

                newPending.idData.forEach(async (d) => {

                    if (!p[0]) return

                    const response = await create_price_pending({
                        typeElement: p[1],
                        idData: d,
                        idLigne: pdt.IdProduit,
                        bigCode: 0,
                        price: p[0],
                    })

                    if (response.status === 201) {
                        message = message + slang("Prix modifié ", "Price changed ") + (p[1] - 6) + " data " + d + " : " + p[0] + "\n"
                    }

                })
            }))

            setShowSpinner4(false)

            displaySnackbar(message, "Success", "success")

            // document.getElementById("pdt-modify-prices-form").reset();

            setPdtOpenToModif(null)

            setNewPending(basicPending)
        }

        // Compose new Pending
        const compose_pending = function (myName, myValue) {

            const newSelection = { ...newPending, [myName]: myValue };

            setNewPending(newSelection);
        }

        return <>
            {(pdt) && <>

                <h3>{t("general.modify") + " " + getNomProduit(pdt)}</h3>
                <form id="pdt-modify-prices-form" onSubmit={modifyProduct}>

                    <div className="row col-md-10 col-sm-12">
                        <div className="row g-1">
                            <button className="btn btn-secondary fs-3 col m-1" type="button" onClick={() => setSelectData(typesSelectData.all)}>All Data</button>
                            <button className="btn btn-secondary fs-3 col m-1" type="button" onClick={() => setSelectData(typesSelectData.none)}>None</button>
                            <span className="border border-dark fs-3 col m-1 text-center">{["ALL", "NONE", "Selection"][selectData]}</span>
                        </div>

                        <MySelectMultiple
                            myName="idData"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel="Data"
                            data={datas.map((d) => ([d.id, d.id + " - " + (d.denomination ?? "").substring(0, 25) + " - " + (d.comment ?? "").substring(0, 30)]))}
                            func={parseInt}
                        />
                    </div>

                    <div className="row row-cols-auto">

                        <MyInput
                            myName="pv1"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel={"pv1"}
                            myType="Number"
                            step={0.01}
                            placeholder={pdt.pv1}
                        />
                        <MyInput
                            myName="pv2"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel={"pv2"}
                            myType="Number"
                            step={0.01}
                            placeholder={pdt.pv2}
                        />
                        <MyInput
                            myName="pv3"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel={"pv3"}
                            myType="Number"
                            step={0.01}
                            placeholder={pdt.pv3}
                        />
                        <MyInput
                            myName="pa"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel={"pa"}
                            myType="Number"
                            step={0.01}
                            placeholder={pdt.pa}
                        />
                    </div>

                    <div className="row my-3 py-3 justify-content-start">

                        <button className="col btn btn-lg fs-3 btn-success m-1" type="submit">{t("general.modify") + " " + getNomProduit(pdt)}</button>
                        <button className="col btn btn-lg fs-3 btn-outline-danger m-1" type="button" onClick={() => {
                            setPdtOpenToModif(null)
                        }}>{t("general.cancel")}</button>
                    </div>
                </form>
            </>
            }
        </>
    }

    // Formulaire de Création d'un nouveau Produit
    const CreatePdtForm = function () {

        const basicPending = {
            idUser: user.id,
            nomProduit: '',
            barcode: '',
            idData: 0,
            IdFamille: 0,
            pv1: 0,
            pv2: 0,
            pv3: 0,
            pa: 0,
            stockable: false,
            estFluide: false,
        }

        const [newPending, setNewPending] = useState(basicPending)

        // Product's Creation
        const createProduct = async function (e) {

            e.preventDefault()

            if (current_idData === 0) return

            if (showSpinner4) return

            setShowSpinner4(true)

            // on récupère d'abord un bigCode

            const response = await fetch(proxyUrl + '/pdt_bigcode')

            let seqId = 0

            if (response.status === 201) {

                seqId = await response.json()

            } else {
                setShowSpinner4(false)
                return
            }

            const body = {
                idData: current_idData,
                nomProduit: newPending.nomProduit,
                barcode: newPending.barcode,
                IdFamille: newPending.IdFamille,
                stockable: newPending.stockable,
                estFluide: newPending.estFluide,
                bigCode: seqId,
                pv1: newPending.pv1,
                pv2: newPending.pv2,
                pv3: newPending.pv3,
                pa: newPending.pa,
            }

            // { idData, barcode, stockable, estFluide, bigCode, pv1, pv2, pv3, pa }

            const response2 = await create_new_pdt(body)

            if (response2.status === 201) {

                displaySnackbar(slang("Produit correctement ajouté dans le data_", "Product created successfully inserted in data_") + current_idData, "Success", "success")

                document.getElementById("create-product-form").reset();
            }

            setShowSpinner4(false)

            setNewPending(basicPending)

        }

        // Compose new Pending
        const compose_pending = function (myName, myValue) {

            const newSelection = { ...newPending, [myName]: myValue };

            setNewPending(newSelection);
        }

        return <>
            <form id="create-product-form" onSubmit={createProduct}>
                {(current_idData > 0) &&

                    <div className="col">

                        <MySelectSearch
                            classInfo="fs-3 btn btn-outline-secondary"
                            myDefaultValue={newPending.IdFamille}
                            myName="IdFamille"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel={slang("Famille", "Family")}
                            data={familles.map((f) => ([f.IdFamille, f.IdFamille + " - " + (f.NomFamille ?? "").substring(0, 30)]))}
                            func={parseInt}
                        />

                        <MyInput
                            myName="nomProduit"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel={"Pdt"}
                            myType="text"
                        />

                        <MyCheckBox
                            myLabel={t("settings.stock")}
                            myName="stockable"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                        />

                        <MyCheckBox
                            myLabel={t("settings.fluid")}
                            myName="estFluide"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                        />

                        <div className="row my-3">
                            {/* Just for spacing */}
                        </div>

                        <MyInput
                            myName="barcode"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel="barcode"
                            myType="text"
                        />
                        <MyInput
                            myName="pv1"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel="pv1"
                            myType="number"
                            step={0.01}
                        />
                        <MyInput
                            myName="pv2"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel="pv2"
                            myType="number"
                            step={0.01}
                        />
                        <MyInput
                            myName="pv3"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel="pv3"
                            myType="number"
                            step={0.01}
                        />

                        <MyInput
                            myName="pa"
                            refresh_itemToSave={compose_pending}
                            idGroupe={getIdGroupe()}
                            myLabel="pa"
                            myType="number"
                            step={0.01}
                        />
                        <button className="btn btn-lg fs-3 btn-success m-1" type="submit">{t("general.add") + " " + t("settings.onglet8").toLowerCase()}</button>
                    </div>
                }
            </form>
        </>
    }

    // Liste des Produits dans le dossier actuel
    const ListeOptions = function ({ pdt }) {

        return <td>
            <button className="btn" onClick={() => selectOnePdt(pdt)}>
                <img style={{ height: "40px", width: "auto" }} src="/images/select.svg" alt="Crayon" />
            </button>

            <button className="btn" onClick={() => {
                setPdtOpenToModif(pdt)
                jump("formModifProduit")
            }
            }>
                <img style={{ height: "35px", width: "auto" }} src="/images/pencil.svg" alt="Crayon" />
            </button>
        </td>
    }
    const ListePdts = function () {

        const [currentItems, setCurrentItems] = useState([]);

        if (current_idDossClt === 0) return <></>

        const LittleYesNo = function ({ bool }) {

            if (bool) return <span style={{ color: "green" }}>Yes</span>

            return <span style={{ color: "gray" }}>No</span>
        }

        const table_titles = [
            "big",
            t("general.name") + " (local_id - data)",
            slang("Prix", "Prices"),
            "Details",
            "Options"
        ]

        return <>
            <PaginatedItems itemsPerPage={10} items={pdts_filtered} setCurrentItems={setCurrentItems} />

            <table className="table">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={table_titles.length} >

                            <NbItems count={currentItems.length} total={pdts_filtered.length} />
                        </td>
                    </tr>
                    {currentItems.map((pdt_elt, idx) => (
                        <tr key={idx}>
                            <th scope="row">{pdt_elt.bigCode}</th>

                            <td>{getNomProduit(pdt_elt)}</td>

                            <td>

                                <p className="m-0 p-0">{slang("pv1","sp1") + ": "} {digitGroup(pdt_elt.pv1)}</p>
                                <p className="m-0 p-0">{slang("pv2","sp2") + ": "} {digitGroup(pdt_elt.pv2)}</p>
                                <p className="m-0 p-0">{slang("pv3","sp3") + ": "} {digitGroup(pdt_elt.pv3)}</p>
                                <p className="m-0 p-0">{slang("pa","cp") + ": "} {digitGroup(pdt_elt.pa)}</p>
                            </td>
                            <td>
                                <p className="m-0 p-0">stk <LittleYesNo bool={pdt_elt.Stockable} /></p>
                                <p className="m-0 p-0">pack <LittleYesNo bool={pdt_elt.EstPack} /></p>
                                <p className="m-0 p-0">fract <LittleYesNo bool={pdt_elt.EstFractionnable} /></p>
                                <p className="m-0 p-0">fluid <LittleYesNo bool={pdt_elt.estFluide} /></p>
                            </td>

                            <ListeOptions pdt={pdt_elt} />
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    // Liste des Pdts with boolean indicating image
    const ListePdtsWithBoolImageExists = function () {

        const [currentItems, setCurrentItems] = useState([]);

        if (current_idDossClt === 0) return <></>

        const LittleYesNo = function ({ bool }) {

            if (bool) return <span style={{ color: "green" }}>Yes</span>

            return <span style={{ color: "gray" }}>No</span>
        }

        const table_titles = [
            "big",
            t("general.name") + " (expected png)",
            "Image",
        ]

        return <>
            <PaginatedItems itemsPerPage={15} items={pdtsWithBoolImageExists} setCurrentItems={setCurrentItems} />

            <table className="table">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={table_titles.length} >

                            <NbItems count={currentItems.length} total={pdtsWithBoolImageExists.length} />
                        </td>
                    </tr>
                    {currentItems.map((pdt_elt, idx) => (
                        <tr key={idx}>
                            <th scope="row">{pdt_elt.bigCode}</th>

                            <td>{pdt_elt.NomProduit + " (" + pdt_elt.IdProduit + "-3)"}</td>

                            <td className="row g-0">
                                <LittleYesNo bool={pdt_elt.ifImageExists} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    // Liste des stocks dans le dossier actuel
    const ListeStock = function () {

        const [currentItems, setCurrentItems] = useState([]);

        if (current_idDossClt === 0) return <></>

        const table_titles = [
            "big",
            t("general.name") + " (local_id) - data",
            t("pending.table_qte"),
            t("settings.magasin")
        ]

        return <>
            <PaginatedItems itemsPerPage={20} items={stock_filtered} setCurrentItems={setCurrentItems} />

            <table className="table">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={table_titles.length} >

                            <NbItems count={currentItems.length} total={stock_filtered.length} />
                        </td>
                    </tr>
                    {currentItems.map((sk_elt, idx) => (
                        <tr key={idx}>
                            <th scope="row">{sk_elt.bigCode}</th>

                            <td>{getNomProduit(sk_elt)}</td>

                            <td>{sk_elt.Qte}</td>

                            <td>{sk_elt.NomMag}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    // Formulaire de création d'une ligne d'appro + Submit
    const FormNewAppro = function () {

        let idGroupeSelectPdts = 0;

        const [newApproItem, setNewApproItem] = useState({
            qty: 0,
            bigCode: 0,
            pa: 0,
            nomProduit: "", // pour affichage dans le tableau
        })

        const handleFormNewApproSubmit = function (e) {

            e.preventDefault()

            // si le remplissage n'est pas complet
            if ((newApproItem.bigCode === 0) || (newApproItem.qty === 0) || (current_idData === 0) || (current_idMagasin === 0)) {

                displaySnackbar(slang("Veuillez remplir le formnulaire svp", "Please fill the form first"), "Warning", "warning")
                return
            }

            // si le produit est déjà dans la liste

            const found = newAppro.some(el => el.bigCode === newApproItem.bigCode)

            if (found) {
                displaySnackbar(slang("Attention ce produit est déjà dans la liste d'approvisionnement", "Please note that this product is already on the supply list."), "Warning", "warning")
                return
            }

            let intermediate_array = newAppro

            intermediate_array.push(newApproItem);

            setNewAppro(intermediate_array)

            setRefreshAppro(!refreshAppro)

            e.target.reset()
        }

        const compose_appro = function (myName, myValue) {

            let newSelection = newApproItem

            if (myName === "bigCode") {
                const pdts_select = document.getElementById("inputGroup" + idGroupeSelectPdts)?.options

                const idx = pdts_select.selectedIndex

                if (idx > -1) newSelection = { ...newSelection, nomProduit: pdts_select[idx].text };
            }

            newSelection = { ...newSelection, [myName]: myValue };

            setNewApproItem(newSelection);
        }

        if (current_idData === 0) return <></>

        return <div className="mt-3 pt-3 col-10 mx-auto">
            <form onSubmit={handleFormNewApproSubmit}>

                <MySelectSearch
                    classInfo="fs-3 btn btn-outline-dark"
                    myDefaultValue={newApproItem.bigCode}
                    myName="bigCode"
                    refresh_itemToSave={compose_appro}
                    idGroupe={(() => {
                        idGroupeSelectPdts = getIdGroupe()
                        return idGroupeSelectPdts
                    })()}
                    myLabel={"Pdt"}
                    data={pdts_filtered.map((p) => ([p.bigCode, (p.NomProduit ?? "").substring(0, 50) + " (" + p.bigCode + ")"]))}
                    func={parseInt}
                />

                <MyInput
                    myName="qty"
                    refresh_itemToSave={compose_appro}
                    idGroupe={getIdGroupe()}
                    myLabel={slang("Quantité", "Quantity")}
                    myType="number"
                    step={0.01}
                    func={parseInt}
                />

                <MyInput
                    myName="pa"
                    refresh_itemToSave={compose_appro}
                    idGroupe={getIdGroupe()}
                    myLabel={slang("Prix unitaire d'achat", "Unit cost's price")}
                    myType="number"
                    step={0.01}
                    func={parseFloat}
                />

                <button className="btn btn-lg fs-3 btn-success m-1" type="submit">{slang("Ajouter 1 ligne", "Add 1 row")}</button>

            </form>
        </div>
    }
    const deleteApproItem = (idx) => {

        let tempArray = newAppro

        tempArray.splice(idx, 1)

        setNewAppro(tempArray)

        setConfirmDelete(false)

        setDelete_approItem(0)
    }
    const ListeOptionsAppro = function ({ approItem }) {

        return <td>
            <button className="btn" onClick={() => {
                setDelete_approItem(approItem)
                setConfirmDelete(!confirmDelete)
            }}>
                <img style={{ height: "35px", width: "auto" }} src="/images/garbage-trash.svg" alt="Corbeille" />
            </button>
            {(confirmDelete && (approItem === delete_approItem)) &&
                <button className="btn btn-lg fs-5 btn-danger" type="button" onClick={deleteApproItem}>{t("general.delete") + " ?"}</button>
            }
        </td>
    }

    return <div className="col-lg-8 col-sm-11 mt-3 mx-auto">

        {/* SELECTIONNE UN DOSSIER */}

        <MySelectSearch
            classInfo="fs-3 btn btn-outline-info"
            myDefaultValue={current_idDossClt}
            myName="idDossierClient"
            refresh_itemToSave={(name, value) => {
                if (isNaN(value)) value = 0
                setCurrent_idDossClt(value)
            }}
            myLabel={t("settings.doss_clt")}
            data={doss_clts.map((d) => ([d.id, d.id + " - " + d.nomClient]))}
            func={parseInt}
            idGroupe={getIdGroupe()}
        />


        {/* FILTRE PAR DATA */}

        {(current_idDossClt > 0) &&

            <MySelectSearch
                classInfo="fs-3 btn btn-outline-primary"
                myDefaultValue={current_idData}
                myName="idData"
                refresh_itemToSave={(name, value) => {

                    setCurrent_idData(value)

                    filterPdtsAndStock(value, choixOnglet)
                }}
                myLabel={"Data"}
                data={datas.map((d) => ([d.id, d.id + " - " + (d.denomination ?? "").substring(0, 25) + " - " + (d.comment ?? "").substring(0, 30)]))}
                func={parseInt}
                idGroupe={getIdGroupe()}
            />
        }

        {((current_idDossClt > 0) && (current_idData > 0)) &&

            <div className="btn-group my-3" role="group" aria-label="Navigation buttons">
                {((choixOnglet === 0) || (choixOnglet === listeOnglets.pdts_liste)) &&
                    <button type="button" className={"fs-3 btn btn-lg btn-" + (choixOnglet === listeOnglets.pdts_liste ? "primary" : "outline-secondary")} onClick={() => handleChoice(listeOnglets.pdts_liste)}>{t("settings.onglet7")}</button>
                }
                {((choixOnglet === 0) || (choixOnglet === listeOnglets.pdts_images)) &&
                    <button type="button" className={"fs-3 btn btn-lg btn-" + (choixOnglet === listeOnglets.pdts_images ? "primary" : "outline-secondary")} onClick={() => handleChoice(listeOnglets.pdts_images)}>IMAGES</button>
                }
                {((choixOnglet === 0) || (choixOnglet === listeOnglets.pdts_stocks)) &&
                    <button type="button" className={"fs-3 btn btn-lg btn-" + (choixOnglet === listeOnglets.pdts_stocks ? "primary" : "outline-secondary")} onClick={() => handleChoice(listeOnglets.pdts_stocks)}>STOCK</button>
                }
                {((choixOnglet === 0) || (choixOnglet === listeOnglets.pdts_appro)) &&
                    <button type="button" className={"fs-3 btn btn-lg btn-" + (choixOnglet === listeOnglets.pdts_appro ? "primary" : "outline-secondary")} onClick={() => handleChoice(listeOnglets.pdts_appro)}>{t("settings.onglet9")}</button>
                }
                {((choixOnglet === 0) || (choixOnglet === listeOnglets.pdts_nouveau)) &&
                    <button type="button" className={"fs-3 btn btn-lg btn-" + (choixOnglet === listeOnglets.pdts_nouveau ? "primary" : "outline-secondary")} onClick={() => handleChoice(listeOnglets.pdts_nouveau)}>{t("settings.onglet8")}</button>
                }
            </div>
        }


        {/* ONGLET LISTE PDTS */}

        {
            (choixOnglet === listeOnglets.pdts_liste) &&
            <>


                <ModifPdtPricesForm pdt={pdtOpenToModif} /> {/* FORMULAIRE DE MODIFICATION DES PRIX */}

                <MySpinner show={showSpinner4} myText={slang("Modifie le produit","Modify Product")} />

                {(!pdtOpenToModif) &&
                    <>

                        {/* FUSION DE PRODUITS */}

                        {pdts_selected.length > 0 && <div className="mb-3 mt-3">

                            <h4>Pdts Selection</h4>

                            <div className="mb-1">
                                {pdts_selected.map((elt, idx) => {
                                    return <button className="btn btn-lg fs-3 btn-outline-primary" key={idx} onClick={(e) => {
                                        const pdts_curr = pdts_selected.slice();

                                        pdts_curr.splice(idx, 1)

                                        setPdts_selected(pdts_curr)
                                    }}>{elt.NomProduit + " (" + elt.IdProduit + "-" + elt.idDataSource + ")"}</button>
                                })}
                            </div>

                            {pdts_selected.length > 1 && <>
                                <button className="btn btn-lg fs-3 btn-info" onClick={() => { setConfirmMergeOpen(!confirmMergeOpen) }}>
                                    {t("settings.pdtMerge") + " Pdts"}
                                </button>
                                {confirmMergeOpen &&
                                    <button className="btn btn-lg fs-3 btn-warning" onClick={mergeProducts}>
                                        {t("general.confirm") + " " + t("settings.pdtMerge")}
                                    </button>
                                }
                            </>
                            }
                            <button className="btn btn-lg fs-3 btn-outline-danger m-1" type="button" onClick={() => setPdts_selected([])}>{t("general.cancel")}</button>
                        </div>}


                        {/* FILTRE LA LISTE */}

                        < div className="input-group mb-1 fs-5">
                            <MyInputSearch
                                myLabel={t("settings.search_pdt")}
                                idGroupe={getIdGroupe()}
                                items={pdts}
                                setItemsFiltered={setPdts_filtered}
                                filt1="IdProduit"
                                filt2="NomProduit"
                            />
                        </div>

                        {/* ANCRE + DOWNLOAD */}

                        <div id="formModifProduit"></div>

                        <CSVLink className="btn btn-lg fs-3 btn-outline-success mt-3 mb-1" data={pdts_filtered} filename={"pdts_list_filtered"}>{t("general.download")}</CSVLink>

                        {/* LISTE DE PRODUITS */}

                        <MySpinner show={showSpinner1} myText={t("general.loading") + " Pdts"} />

                        <ListePdts />
                    </>
                }
            </>
        }

        {/* ONGLET IMAGES */}

        {
            ((choixOnglet === listeOnglets.pdts_images) && (current_idData > 0)) && <>

                <form id="form_images"
                    className="border border-light rounded p-3"
                    encType="multipart/form-data"
                    onSubmit={async (e) => {
                        e.preventDefault()

                        const url = proxyUrl + "/create_png_images"

                        const formData = new FormData();

                        formData.append('idData', current_idData)

                        var files = document.getElementById('filesToUpload').files;

                        for (var x = 0; x < files.length; x++) {

                            formData.append('myImages', files[x])
                        }

                        const response = await fetch(url, {
                            method: "POST",
                            headers: {
                                "Accept": "multipart/form-data",
                            },
                            body: formData,
                            credentials: "include",
                        })

                        displaySnackbar((await response.json()).msg, "Upload", "success");

                        e.target.reset()
                    }}
                >
                    <div className="row my-3 p-1">
                        <label className="col-4" htmlFor="file">jpg / png images &nbsp; </label>

                        <input className="col-6" type="file" multiple="multiple" accept=".png, .jpg, .jpeg" name="myImages" id="filesToUpload" />
                        <button className="col-2 btn btn-lg btn-secondary" type="submit">Upload</button>
                    </div>
                </form>

                {/* LISTE DE PDTS AVEC INDICATION DE SI L'IMAGE ASSOCIEE EXISTE */}

                <MySpinner show={showSpinner2} myText={t("general.loading") + " Pdts with Image Indication"} />

                <ListePdtsWithBoolImageExists />
            </>
        }

        {/* ONGLET STOCKS */}

        {
            (choixOnglet === listeOnglets.pdts_stocks) &&
            <>

                {/* FILTRE LA LISTE */}

                <div className="input-group mb-1 fs-5">
                    <MyInputSearch
                        myLabel={t("settings.search_pdt")}
                        idGroupe={getIdGroupe()}
                        items={stock}
                        setItemsFiltered={setStock_filtered}
                        filt1="IdProduit"
                        filt2="NomProduit"
                    />
                </div>


                {/* TELECHARGEMENT DE STOCK */}

                <CSVLink className="btn btn-lg fs-3 btn-outline-success mt-3 mb-1" data={stock_filtered} filename={"stocks_list_filtered"}>{t("general.download")}</CSVLink>

                {/* LISTE DE STOCK */}

                <MySpinner show={showSpinner3} myText={t("general.loading") + " Stocks"} />

                <ListeStock />
            </>
        }

        {/* ONGLET CREATION D'UN PRODUIT */}

        {
            ((choixOnglet === listeOnglets.pdts_nouveau) && (current_idDossClt > 0)) &&
            <>

                <CreatePdtForm />

                <MySpinner show={showSpinner4} myText={slang("Enregistre le nouveau Produit","Save New Product")} />
            </>
        }

        {/* ONGLET APPRO */}

        {

            ((choixOnglet === listeOnglets.pdts_appro) && (current_idDossClt > 0)) &&
            <>
                {(current_idData > 0) &&

                    <MySelectSearch
                        classInfo="fs-3 btn btn-outline-primary"
                        myDefaultValue={current_idMagasin}
                        myName="idMagasin"
                        refresh_itemToSave={(myName, myValue) => {
                            setCurrent_idMagasin(myValue)
                        }}
                        idGroupe={getIdGroupe()}
                        myLabel={slang("Magasin", "Store")}
                        data={magasins.map((m) => ([m.IdMagasin, (m.NomMagasin ?? "").substring(0, 50) + " (" + m.IdMagasin + ")"]))}
                        func={parseInt}
                    />
                }

                {(current_idMagasin > 0) &&
                    <FormNewAppro />
                }

                {(newAppro.length > 0) &&

                    <>
                        <p className="text-primary fs-2 mt-3 pt-3">{slang("Appro", "Supply") + " nb: " + newAppro.length}</p>
                        <table className="table">
                            <thead>
                                <tr>
                                    {[
                                        slang("Produit", "Product"),
                                        slang("Qté", "Qty"),
                                        slang("Prix unit. d'achat", "Unit Cost's Price")
                                    ].map((title_elt, i) => (
                                        <th key={i} scope="col">{title_elt}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {newAppro.map((appro, idx) => (
                                    <tr key={idx}>
                                        <td>{appro.nomProduit}</td>
                                        <td>{appro.qty}</td>
                                        <td>{appro.pa}</td>
                                        <ListeOptionsAppro approItem={idx} />
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {(showSpinner4 === false) &&
                            <button className="col-4 btn btn-lg fs-3 btn-success m-1"
                                type="button"
                                onClick={async () => {

                                    setShowSpinner4(true)

                                    const body = {
                                        idData: current_idData,
                                        typeElement: 13,
                                        idLigne: current_idMagasin,
                                        bigCode: 0,
                                        price: 0,
                                        idUser: user.id,
                                        content: newAppro,
                                    }

                                    const response = await fetch(proxyUrl + "/pending", {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            // "Accept": "multipart/form-data",
                                        },
                                        credentials: "include",

                                        // Pending : 1. Reset pwd  2. Remise accordée  3. Suppr tk accordée  4. Transfert validé
                                        //   5. Transfert en attente de validation  6. Transfert refusé  7. maj PV1 Pdt  8. maj PV2 Pdt  9. maj PV3 Pdt  10. maj PA Pdt  11. maj bigCode Pdt

                                        body: JSON.stringify(body),
                                    })

                                    if (response.status === 201) {

                                        displaySnackbar(slang("Approvisionnement ajouté", "Supply Added"), "data_" + current_idData + " mag_" + current_idMagasin, "success")
                                        setNewAppro([])
                                    }

                                    setShowSpinner4(false)

                                }}
                            >
                                {slang("Valider Appro", "Vaalidate Supply")}
                            </button>
                        }

                        <MySpinner show={showSpinner4} myText={slang("Enregistre l'Appro","Save Supply")} />
                    </>
                }
            </>
        }

        <MyScrollButton />
    </div >
}

export default SettingsPdts;