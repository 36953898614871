import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { MyCheckBox, MyInput } from "../FormElements";
import { displaySnackbar, slang } from '../Utils';

const proxyUrl = require("../../../package.json").proxy;

function ProcesseurForm(props) {

    const { t } = useTranslation();

    const current_item = props.proc;
    const bool_modify = Boolean(current_item);

    const [procToSave, setProcToSave] = useState({
        active: bool_modify ? current_item.active : false,
        procName: bool_modify ? current_item.procName : "0",
    })

    const refresh_procToSave = function (myName, myValue) {

        const newComp = { ...procToSave, [myName]: myValue }

        setProcToSave(newComp);
    }


    // Création et Modification
    const handleSubmit = async (e) => {
        e.preventDefault();

        const url = proxyUrl + (!bool_modify ? "/proc" : "/proc/" + current_item.id);
        const method = "POST";
        
        // Logique pour enregistrer

        const response = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(procToSave),
        })

        if (response.status === 201) {
            props.refresh_list()
            displaySnackbar(slang("Modification effectuée","Saved succesfully"),"OK","success")
        }

    };

    return (
        <div>
            {bool_modify ?
                <h2>{t("general.modify") + " " + current_item.procName + " id_" + current_item.id}</h2>
                :
                <h2>{t("general.add")}</h2>
            }

            <form className="col-lg-4 col-md-8 mx-auto border-bottom border-secondary mb-3" onSubmit={handleSubmit}>

                <MyInput
                    myName="procName"
                    refresh_itemToSave={refresh_procToSave}
                    idGroupe={1}
                    myLabel={t("composante_form.name")}
                    myType="text"
                />

                <MyCheckBox
                    myLabel={t("composante.active")}
                    myName="active"
                    refresh_itemToSave={refresh_procToSave}
                />

                <button className="btn btn-lg fs-4 btn-success" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>
            </form>
        </div>
    );
}

export default ProcesseurForm;