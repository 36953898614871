import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { MyCheckBox, MyInput, MySelect, MyImage, MyImagePreview } from "../FormElements";
import { displaySnackbar, rangDossiers, slang } from "../Utils"

const proxyUrl = require("../../../package.json").proxy;

function OrdiForm(props) {

    const { t } = useTranslation();

    const current_item = props.ordi;
    const bool_modify = Boolean(current_item);

    // A. id: Number, // autoincrement
    // B. typeOrdi: Number, // 1. Desktop, 2. Laptop, 3. All-in-one
    // C. idProc: Number, // AMD 1_Athlon 2_Ryzen3 3_Ryzen5 4_Ryzen7 INTEL 5_Core2duo 6_Corei3 7_Corei5 8_Corei7 9_Corei9
    // D. processeurGeneration: Number,
    // E. denomination: String,
    // F. graphDedie: Number, // en Mo sera affiché comme première option sur les desktops, les 2 autres n'auront pas ça
    // G. graphTotale: Number, // en Mo
    // H. graphMarque: String,
    // I. typeram: Number, // DDR 1, 2, 3, 4
    // J. ecranTaille: Number, // en pouces - 0 est valide pour les desktops
    // K. ecranTactile: Boolean,
    // L. price: Number, // FCFA
    // M. active: Boolean, // s'il n'y a plus aucune pièce d'un modèle on doit pouvoir le désactiver, comme ça il n'apparaît plus dans les résultats

    const [ordiToSave, setOrdiToSave] = useState({
        id: bool_modify ? current_item.id : 0,
        typeOrdi: bool_modify ? current_item.typeOrdi : 0,
        idProc: bool_modify ? current_item.idProc : 0,
        processeurGeneration: bool_modify ? current_item.processeurGeneration : 0,
        denomination: bool_modify ? current_item.denomination : "",
        graphDedie: bool_modify ? current_item.graphDedie : 0,
        graphTotale: bool_modify ? current_item.graphTotale : 0,
        graphMarque: bool_modify ? current_item.graphMarque : "",
        typeram: bool_modify ? current_item.typeram : 0,
        ecranTaille: bool_modify ? current_item.ecranTaille : 0,
        ecranTactile: bool_modify ? current_item.ecranTactile : false,
        price: bool_modify ? current_item.price : 0,
        active: bool_modify ? current_item.active : false,
    })

    const refresh_ordiToSave = function (myName, myValue) {

        const newOrdi = { ...ordiToSave, [myName]: myValue };

        // Gestion des cases qui impactent d'autres cases quand on les remplit

        if (myName === "typeOrdi") {

            switch (myValue) {
                case 1: // Ici on passe à Desktop
                    newOrdi.ecranTaille = 0;
                    newOrdi.ecranTactile = false;
                    break;

                case 2: // Ici on passe à Laptop, All-in-one
                case 3:
                default:
                    break;
            }

        }

        setOrdiToSave(newOrdi);
    }

    const [image, setImage] = useState(null);

    // AMD 1_Athlon 2_Ryzen3 3_Ryzen5 4_Ryzen7 INTEL 5_Core2duo 6_Corei3 7_Corei5 8_Corei7 9_Corei9

    const LISTE_TYPE_ORDI = [
        [1, "Desktop"],
        [2, "Laptop"],
        [3, "All-in-one"],
    ];

    const LISTE_RAM = [
        [1, "DDR 1"],
        [2, "DDR 2"],
        [3, "DDR 3"],
        [4, "DDR 4"],
    ];


    // Modification
    const handleModify = async () => {

        let url = "";

        // Modification éventuelle de l'image
        if (image) {
            url = proxyUrl + "/create_image"

            const formData = new FormData();

            formData.append('idElement', ordiToSave.id)
            formData.append('rangDossier', rangDossiers.ordi)
            formData.append('idData', 0)
            formData.append('myImage', image)

            // Logique pour enregistrer ici

            fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "multipart/form-data",
                },
                body: formData,
                credentials: "include",
            })
        }

        url = proxyUrl + "/ordi/" + current_item.id;

        // Logique pour enregistrer

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",
            body: JSON.stringify(ordiToSave),
        })

        if (response.status === 201) {
            props.refresh_list()
            displaySnackbar(slang("Modification effectuée", "Saved succesfully"), "OK", "success")
            return
        }

        const data = await response.json();

        displaySnackbar(data.message, "Message from server", "light")
    }

    // Création
    const handleCreation = async () => {

        const url = proxyUrl + "/ordi"
        const formData = new FormData();

        if (!image) {
            displaySnackbar(
                slang("Veuillez sélectionner une image svp", "Please an image is required"),
                slang("Image requise", "Choose an image"),
                "warning")
            return
        }
        if (ordiToSave.idProc === 0) {
            displaySnackbar(
                slang("Veuillez sélectionner un processeur svp", "Please select a processor required"),
                slang("Processeur requis", "Processor is required"),
                "warning")
            return
        }

        formData.append("typeOrdi", ordiToSave.typeOrdi)
        formData.append("idProc", ordiToSave.idProc)
        formData.append("processeurGeneration", ordiToSave.processeurGeneration)
        formData.append("denomination", ordiToSave.denomination)
        formData.append("graphDedie", ordiToSave.graphDedie)
        formData.append("graphTotale", ordiToSave.graphTotale)
        formData.append("graphMarque", ordiToSave.graphMarque)
        formData.append("typeram", ordiToSave.typeram)
        formData.append("ecranTaille", ordiToSave.ecranTaille)
        formData.append("ecranTactile", ordiToSave.ecranTactile)
        formData.append("price", ordiToSave.price)
        formData.append("active", ordiToSave.active)
        formData.append("myImage", image)

        // Logique pour enregistrer le post ici

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Accept": "multipart/form-data",
            },
            body: formData,
            credentials: "include",
        })

        if (response.status === 201) {
            props.refresh_list()
            return
        }

        const data = await response.json();

        displaySnackbar(data.message, "Message from server", "light")
    }

    // Bouton Valider
    const handleSubmit = (e) => {
        e.preventDefault();

        if (bool_modify) {
            handleModify();
        } else {
            handleCreation();
        }
    };

    return (
        <div>
            {bool_modify ?
                <h2>{t("general.modify") + " " + current_item.denomination + " id_" + current_item.id}</h2>
                :
                <h2>{t("general.add")}</h2>
            }

            <form className="col-lg-10 col-md-11 mx-auto border-bottom border-secondary m-1 mb-3" onSubmit={handleSubmit}>
                <div className="row">

                    {/* M. active */}

                    <MyCheckBox
                        idGroupe={13}
                        myLabel={t("composante.active")}
                        myName="active"
                        refresh_itemToSave={refresh_ordiToSave}
                    />

                    {/* E. denomination */}

                    <MyInput
                        myName="denomination"
                        refresh_itemToSave={refresh_ordiToSave}
                        idGroupe={5}
                        myLabel="Nom"
                        myType="text"
                    />

                    {/* B. typeOrdi */}

                    <MySelect
                        myName="typeOrdi"
                        setMyValue={setOrdiToSave}
                        refresh_itemToSave={refresh_ordiToSave}
                        idGroupe={2}
                        myLabel="Type"
                        data={LISTE_TYPE_ORDI}
                        func={parseInt}
                    />

                    {/* C. idProc */}

                    <MySelect
                        myName="idProc"
                        setMyValue={setOrdiToSave}
                        refresh_itemToSave={refresh_ordiToSave}
                        idGroupe={3}
                        myLabel="Proc.Type"
                        data={props.LISTE_PROC_TYPE}
                        func={parseInt}
                    />

                    {/* D. processeurGeneration */}

                    <MyInput
                        myName="processeurGeneration"
                        refresh_itemToSave={refresh_ordiToSave}
                        idGroupe={4}
                        myLabel="Proc.Gen"
                        myType="number"
                        func={parseInt}
                    />

                    {/* I. typeram */}

                    <MySelect
                        myName="typeram"
                        setMyValue={setOrdiToSave}
                        refresh_itemToSave={refresh_ordiToSave}
                        idGroupe={9}
                        myLabel="RAM Type"
                        data={LISTE_RAM}
                        func={parseInt}
                    />

                    {/* L. price */}

                    <MyInput
                        myName="price"
                        refresh_itemToSave={refresh_ordiToSave}
                        idGroupe={12}
                        myLabel={<span>Prix de base <small>(sans ram ni disque)</small></span>}
                        myType="Number"
                        step={0.01}
                        func={parseFloat}
                    />






                </div>


                {(ordiToSave.typeOrdi === 2 || ordiToSave.typeOrdi === 3) &&
                    <>
                        {/* J. ecranTaille */}

                        <MyInput
                            myName="ecranTaille"
                            refresh_itemToSave={refresh_ordiToSave}
                            idGroupe={10}
                            myLabel="Taille Ecran ('')"
                            myType="Number"
                            step={0.1}
                            func={parseFloat}
                        />

                        {/* K. ecranTactile */}

                        <MyCheckBox
                            myLabel={t("ordi.tactile")}
                            myName="ecranTactile"
                            refresh_itemToSave={refresh_ordiToSave}
                            idGroupe={11}
                        />
                    </>
                }


                <div className="row">

                    <div className="col m-2 rounded" style={{ backgroundColor: "bisque", paddingTop: "10px" }}>

                        <div className="col-6 my-2">
                            <strong>Graphique</strong>
                        </div>


                        {/* H. graphMarque */}
                        <div className="col-10 mx-auto">

                            <MyInput
                                myName="graphMarque"
                                refresh_itemToSave={refresh_ordiToSave}
                                idGroupe={8}
                                myLabel="Marque"
                                myType="text"
                            />
                        </div>

                        {/* F. graphDedie */}
                        <div className="col-10 mx-auto">

                            <MyInput
                                myName="graphDedie"
                                refresh_itemToSave={refresh_ordiToSave}
                                idGroupe={6}
                                myLabel="Dédiée (Mo)"
                                myType="Number"
                                func={parseInt}
                            />
                        </div>

                        {/* G. graphTotale */}
                        <div className="col-10 mx-auto">
                            <MyInput
                                myName="graphTotale"
                                refresh_itemToSave={refresh_ordiToSave}
                                idGroupe={7}
                                myLabel="Totale (Mo)"
                                myType="Number"
                                func={parseInt}
                            />
                        </div>


                    </div>

                </div>

                {/* Image */}

                <MyImage
                    myLabel="image"
                    setImage={setImage}
                />

                <MyImagePreview
                    image={image}
                    id_image={current_item?.id}
                    bln_modify={bool_modify}
                    base_url={proxyUrl + "/uploads/0/id_image-" + rangDossiers.ordi + ".jpg"}
                />

                <div className="col-5">
                    {/* Validation */}

                    <button className="btn btn-lg fs-3 w-75 btn-success" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>
                </div>

            </form >
        </div >
    );
}

export default OrdiForm;