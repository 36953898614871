import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import defaultPlaceholderImg from "../images/default-placeholder.png";
import { format_date } from "../Utils";

const proxyUrl = require("../../../package.json").proxy;

const RecentPosts = function () {

    const NB_SHOWED_FIRST = 3;
    const NB_ADDED = 3;
    const NB_CHARS_BY_POST = 50;

    const { t } = useTranslation();

    const [limit, setLimit] = useState(NB_SHOWED_FIRST) // nombre de posts affichés
    const [posts, setPosts] = useState([])

    useEffect(() => {

        const fetchPosts = async () => {
            try {
                const response = await fetch(proxyUrl + '/posts/' + limit);
                const data = await response.json();
                setPosts(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchPosts();

    }, [limit])

    return <div className="posts">
        {posts.map((post) => (

            <div key={post.id} className="col-lg-8 col-md-10 mx-auto">

                <div className="p-4 mb-4 bg-body-tertiary rounded-3 border border-dark-subtle text-center">
                    <h1 className="display-5">{post.title}</h1>
                    <p className="lead fs-2 text-primary">{format_date(post.createdAt)}</p>
                    <hr className="my-4" />

                    <a className="text-decoration-none" href={"/post/" + post.id}>
                    <img className="mw-100 rounded" style={{ maxHeight: "300px" }} src={proxyUrl + "/uploads/0/" + post.id + "-4.jpg"} onError={(e) => { e.target.onerror = null; e.target.src = defaultPlaceholderImg }} alt="Full Size" />
                    </a>

                    <p className="fs-3">{post.content.substring(0, NB_CHARS_BY_POST) + "..."}</p>
                    <p className="lead">
                        <a className="btn btn-secondary btn-lg fs-2" role="button" href={"/post/" + post.id}>{t("post.read_more")}</a>
                    </p>
                </div>
            </div>
        ))}

        <div className="col-4 mx-auto">
            
            {(limit <= posts.length) &&
                <button className="btn btn-lg btn-warning w-100 fs-3 my-3" onClick={() => setLimit(limit + NB_ADDED)}>{t("post.show_more")}</button>
            }

            <button className="btn btn-lg btn-outline-success w-100 fs-2 my-3" onClick={() => window.scrollTo(0, 0)}>{t("home.btn_scroll_top")}</button>
        </div>
    </div >
}

export default RecentPosts;